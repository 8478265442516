import React, { useEffect } from "react";
import { useHistory } from 'react-router-dom';
import './AddPatient.css'
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Datetime from "react-datetime";
import { Typeahead } from "react-bootstrap-typeahead";
import Button from "@material-ui/core/Button";

import MenuItem from '@material-ui/core/MenuItem';

import Camera from 'react-html5-camera-photo';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';

import DateFnsUtils from '@date-io/date-fns';

import InputMask from 'react-input-mask';

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import DialogActions from "@material-ui/core/DialogActions";

import Grid from "@material-ui/core/Grid";

import InputLabel from '@material-ui/core/InputLabel';

import { DatePicker, KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import Select from '@material-ui/core/Select';
import Paper from "@material-ui/core/Paper";

import FormControlLabel from "@material-ui/core/FormControlLabel";

import Slide from "@material-ui/core/Slide";

import Modal from "@material-ui/core/Modal";
import Checkbox from '@material-ui/core/Checkbox';

import PictureAsPdfSharpIcon from "@material-ui/icons/PictureAsPdfSharp";
import Switch from "@material-ui/core/Switch";

import MaterialTable from "material-table";

import Autocomplete from "@material-ui/lab/Autocomplete";

import FormControl from "@material-ui/core/FormControl";
// for retake image of patient
import CloseIcon from '@material-ui/icons/Close';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';

import { setHours } from "date-fns";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  paper: {
    position: "absolute",
    width: 1000,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    marginLeft: "13em",
    marginTop: "5em",
    padding: theme.spacing(2, 4, 3),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  switch: {
    marginLeft: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
    float: "right",
  }
}));
function titleCase(str) {
  var splitStr = str.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(' ');
}

export default function AddPatient() {
  const classes = useStyles();
  const [years,setYears]=React.useState(0)
  const [month,setMonth]=React.useState(0)
  const [day,setDay]=React.useState(0)
  const [dataUri, setDataUri] = React.useState('');
  const [checkedTakePhoto, setCheckedTakePhoto] = React.useState(true);
  const [checkedUploadImage, setCheckedUploadImage] = React.useState(false);
  const [doctor, setDoctor] = React.useState(true)
  const [employee, setEmployee] = React.useState(false)
  const [firstName, setfirstName] = React.useState('')
  const [lastName, setlastName] = React.useState('')
  const [fatherName, setfatherName] = React.useState('')
  const [mobile, setMobile] = React.useState('')
  const [CNIC, setCNIC] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [phone, setPhone] = React.useState('')
  const [network, setNetwork] = React.useState('Select')
  const [address, setAddress] = React.useState('')
  const history = useHistory();
   //////////////

   const [PassportImage, setPassportImage] = React.useState('');

   /////////////
  const [EntitlementList,setEntitlementList]=React.useState([])
  const [EntitlementID,setEntitlementID]=React.useState("")
  const [EntitlementName,setEntitlementName]=React.useState("")
  const [EntitlementType,setEntitlementType]=React.useState("")

  const [passport, setPassport] = React.useState('')
  const [Fax, setFax] = React.useState('')
  const [Ref, setRef] = React.useState('')
  const [country, setCountry] = React.useState('Pakistan')
  const [district, setDistrict] = React.useState('')
  const [Designation, setDesignation] = React.useState('')
  const [panel, setPanel] = React.useState('')
  const [panelList,setPanelList]=React.useState([])
  const [husband, setHusband] = React.useState('')
  const [Date_Birth, setDate_Birth] = React.useState(new Date("01/01/1900"))
  const [gender, setGender] = React.useState('Select')
  const [employeeName, setEmployeeName] = React.useState('')
  const [relation, setRelation] = React.useState('')
  const [title, setTitle] = React.useState('Select')
  const [city, setCity] = React.useState('')
  const [National, setNational] = React.useState("true")
  const [register, setRegister] = React.useState(false)
  const [province,setProvince]=React.useState('')
  const [DateFormat,setDateFormat]=React.useState(true)
  const [CheckByPass,setCheckByPass]=React.useState(false)
  const [AlreadyExists,setAlreadyExists]=React.useState(false)

  // Adding Internation Phone 
  const [phoneCategory,setPhoneCategory]=React.useState("national")
  // For Other than Human 10-jan-2023
  const [checkedOTH, setcheckedOTH] = React.useState(false);
  const [genderN, setGenderN] = React.useState('N/A')
  const [Date_BirthN, setDate_BirthN] = React.useState("00/00/0000")




  const provinceList =[
    'PUNJAB',
    'ISLAMABAD',
    'KP',
    'SINDH',
    'AJK',
    'BALOCHISTAN',
    'GBALTISTAN'
  ]
  const districtList = [
    'ABOTABAD'
    , 'AFGHAN REFUGEES'
    , 'ASTORE'
    , 'ATTOCK'
    , 'AWARAN'
    , 'BADIN'
    , 'BAGH'
    , 'BAHAWALPUR'
    , 'BAHWLNAGAR'
    , 'BAJOUR'
    , 'BANNU'
    , 'BARKHAN'
    , 'BATAGRAM'
    , 'BHAKKAR'
    , 'BHIMBER'
    , 'BOLAN'
    , 'BUNER'
    , 'CDA'
    , 'CHAGHAI'
    , 'CHAKWAL'
    , 'CHARSADA'
    , 'CHINIOT'
    , 'CHITRAL'
    , 'DADU'
    , 'DBUGTI'
    , 'DGKHAN'
    , 'DIAMER'
    , 'DIKHAN'
    , 'DIR'
    , 'DIRLOWER'
    , 'DIRUPPER'
    , 'DUKKI'
    , 'FAISALABAD'
    , 'GHANCHE'
    , 'GHIZER'
    , 'GHOTKI'
    , 'GILGIT'
    , 'GUJRANWALA'
    , 'GUJRAT'
    , 'GWADUR'
    , 'HAFIZABAD'
    , 'HANGU'
    , 'HARIPUR'
    , 'HARNAI'
    , 'HAVELI'
    , 'HUNZA'
    , 'HUNZANAGAR'
    , 'HYDERABAD'
    , 'ICT'
    , 'JACOBABAD'
    , 'JAFARABAD'
    , 'JAMSHORO'
    , 'JEHLUM_VALLEY'
    , 'JHALMAGSI'
    , 'JHANG'
    , 'JHELUM'
    , 'KABDULAH'
    , 'KACHI'
    , 'KALAT'
    , 'KAMBAR'
    , 'KARACHI'
    , 'KARAK'
    , 'KASHMORE'
    , 'KASUR'
    , 'KECH'
    , 'KHAIRPUR'
    , 'KHANEWAL'
    , 'KHARAN'
    , 'KHARMANG'
    , 'KHIBALDIA'
    , 'KHIBINQASIM'
    , 'KHIGADAP'
    , 'KHIGIQBAL'
    , 'KHIGULBERG'
    , 'KHIJAMSHEED'
    , 'KHIKAMARI'
    , 'KHIKORANGI'
    , 'KHILANDHI'
    , 'KHILAYARI'
    , 'KHILIAQAT'
    , 'KHIMALIR'
    , 'KHINNAZIM'
    , 'KHINORTH'
    , 'KHIORANGI'
    , 'KHISADDAR'
    , 'KHISHAHFAISAL'
    , 'KHISITE'
    , 'KHUSHAB'
    , 'KHUZDAR'
    , 'KHYBER'
    , 'KOHAT'
    , 'KOHISTAN'
    , 'KOHISTANLOWER'
    , 'KOHISTANUPPER'
    , 'KOHLU'
    , 'KOLAIPALAS'
    , 'KOTLI'
    , 'KSAIFULAH'
    , 'KURRAM'
    , 'LAHORE'
    , 'LAKKIMRWT'
    , 'LARKANA'
    , 'LASBELA'
    , 'LAYYAH'
    , 'LODHRAN'
    , 'LORALAI'
    , 'MALAKAND'
    , 'MANSEHRA'
    , 'MARDAN'
    , 'MASTUNG'
    , 'MATIARI'
    , 'MBDIN'
    , 'MIANWALI'
    , 'MIRPUR'
    , 'MIRPURKHAS'
    , 'MOHMAND'
    , 'MULTAN'
    , 'MUSAKHEL'
    , 'MUZAFFARABAD'
    , 'MUZFARGARH'
    , 'NAGAR'
    , 'NANKANASAHIB'
    , 'NAROWAL'
    , 'NAWABSHAH'
    , 'NEELUM'
    , 'NFEROZ'
    , 'NOSHKI'
    , 'NOWSHERA'
    , 'NSIRABAD'
    , 'OKARA'
    , 'ORAKZAI'
    , 'PAKPATTEN'
    , 'PANJGOUR'
    , 'PESHAWAR'
    , 'PISHIN'
    , 'POONCH'
    , 'QUETTA'
    , 'RAJANPUR'
    , 'RAWALPINDI'
    , 'RYKHAN'
    , 'SAHIWAL'
    , 'SANGHAR'
    , 'SARGODHA'
    , 'SBENAZIRABAD'
    , 'SHANGLA'
    , 'SHARANI'
    , 'SHEIKHUPURA'
    , 'SHIGAR'
    , 'SHIKARPUR'
    , 'SIALKOT'
    , 'SIBI'
    , 'SKARDU'
    , 'SOHBATPUR'
    , 'SSIKANDARABAD'
    , 'SUDNUTI'
    , 'SUJAWAL'
    , 'SUKKUR'
    , 'SWABI'
    , 'SWAT'
    , 'T.ALLAHYAR'
    , 'TANK'
    , 'THARPARKAR'
    , 'THATTA'
    , 'TMKHAN'
    , 'TORGHAR'
    , 'TTSINGH'
    , 'UMERKOT'
    , 'VEHARI'
    , 'WASHUK'
    , 'WAZIR-N'
    , 'WAZIR-S'
    , 'ZHOB'
    , 'ZIARAT'
  ]
  const handleChange = (event) => {
    setPanel(event.target.value)
  }
  useEffect(() => {
    if (localStorage.getItem('org_branch_id') == null || localStorage.getItem('org_branch_id') == undefined) {
      localStorage.clear()
      window.location.href = "https://invoicing.mpl-labs.pk/login"
    }
    var obj22={
      branch_id:localStorage.getItem("branch_id")
    }
    fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebPortal_GetPanelsListBranch",
    {
           method:"POST",
    'Content-Type':"application/json",
    body: JSON.stringify(obj22)
    }
    ).then((res) => res.json()).then((response) => {
      response.push({
        Panel_Code: "Normal",
        Panel_Title: "Normal Patient"
      })
      setPanelList(response)
     
    })
    if (localStorage.getItem('user_id') == null || localStorage.getItem('user_id') == undefined) {
      window.location.href = "https://invoicing.mpl-labs.pk/login"
    }

    fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebPatientsEntitlementGet").then((res)=>res.json()).then((response)=>{
 setEntitlementList(response) 
 console.log("AddPatient UseEffect 366 entitlementList jo employee and doctor hn :",response)    
      })
  }, [])
  const handleChangeTake = (event) => {
    setCheckedTakePhoto(event.target.checked);
    setCheckedUploadImage(!event.target.checked)
  };
  const handleChangeUpload = (event) => {
    setCheckedUploadImage(event.target.checked);
    setCheckedTakePhoto(!event.target.checked)
  };
  // For Other than Human 10-jan-2023
const handleChangeOTH = (event) => {
  setcheckedOTH(event.target.checked);
  // setCheckedUploadImage(!event.target.checked)
};

  function handleTakePhotoAnimationDone(dataUri) {
    console.log(dataUri);
    setDataUri(dataUri);
  }
  const handleScanImage = async (event) => {
    const file = event.target.files[0]
    var fileInput = document.getElementById('ScanImage');
    var filePath = fileInput.value;
    var allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
    if(!allowedExtensions.exec(filePath)){
        alert('Please upload file having extensions .jpeg/.jpg/.png only.');
        fileInput.value = '';
        dataUri=""
        return false;
    }else{
    const base64 = await convertBase64(file)
    setDataUri(base64);
    }

  }
    /////////////////
    const handleScanPassport = async (event) => {
      const file = event.target.files[0]
      var fileInput = document.getElementById('PassportImage');
      var filePath = fileInput.value;
      var allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
      if(!allowedExtensions.exec(filePath)){
          alert('Please upload file having extensions .jpeg/.jpg/.png only.');
          fileInput.value = '';
          PassportImage=""
          return false;
      }else{
      const base64 = await convertBase64(file)
      setPassportImage(base64);
      }
  
    }
    ////////////////
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }

  return (
    <div className={classes.root}>
      <form noValidate autoComplete="off">
       <div>
         
          <Grid container>
          <Grid item xs={6} sm={6} md={6} lg={6}>
      {/*// For Other than Human 10-jan-2023*/}
      <Checkbox style={{marginLeft:10}}
        checked={checkedOTH}
        onChange={handleChangeOTH}
        inputProps={{ 'aria-label': 'primary checkbox' }}
        
      />
         <b style={{marginTop:0,marginRight:30}}>Other Than Human</b>
         {/* end of OTH */}
            </Grid>
             <Grid item xs={3} sm={3} md={3} lg={3}  >
     <b>Take Photo</b>
      <Checkbox
        checked={checkedTakePhoto}
        onChange={handleChangeTake}
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
       <b>Upload Image</b>
      <Checkbox
        checked={checkedUploadImage}
        onChange={handleChangeUpload}
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />

          </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3} >
      <div style={{width:'10em'}}>
      { checkedTakePhoto ?
        (dataUri)

          ?<>
          <div style={{position:'absolute'}}> <img src={dataUri}
          width={250} 
          />
          </div>
          <CancelOutlinedIcon onClick={()=>{setDataUri("")}}
           style={{
            position:'absolute',
            // top:"33%",
            // right:"12%"
            }}/>
          </>
          // <></>
          : 
         <div style={{position:'absolute',}}>
          <Camera  onTakePhotoAnimationDone = {handleTakePhotoAnimationDone} />
          </div>
         
          :
          // checkTakePhoto else condtion 455

          <div style={{position:'absolute'}}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
          > 
          <img src={dataUri}
          width={100} 
          />
                 <input type="file" 
                 variant="contained"
            color="primary"
            className={classes.button} 
            id="ScanImage" 
            accept="image/*"
            onChange={(e)=>{handleScanImage(e)}}
            aria-label="Attach PDF"
          />
          </Button>
          </div>

      }
    </div>
      </Grid>
   
          </Grid>
          <Grid container >
            <Grid item xs={2} sm={2} md={2} lg={2}>
              <FormControl variant="filled" className={classes.formControl} style={{ width: "-webkit-fill-available" }} >
                <InputLabel id="demo-simple-select-filled-label">Title</InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={title}
                  onChange={(e) => {
                    setTitle(e.target.value)
                    var res = e.target.value
                    if (res == 'Miss' || res == 'Mrs.' || res == 'Madam' || res == 'Ms.') {
                      setGender("Female")
                    }
                    if (res == "Mr." || res == "Sir") {
                      setGender("Male")
                    }
                  }}
                >
                  <MenuItem disabled={true} value={"Select"}>Select</MenuItem>

                  <MenuItem value={"Mr."}>Mr.</MenuItem>
                  <MenuItem value={"Ms."}>Ms.</MenuItem>
                  <MenuItem value={"Mrs."}>Mrs.</MenuItem>
                  <MenuItem value={"Miss"}>Miss</MenuItem>
                  <MenuItem value={"Baby"}>Baby</MenuItem>
                  <MenuItem value={"Master"}>Master</MenuItem>
                  <MenuItem value={"Sir"}>Sir</MenuItem>
                  <MenuItem value={"Madam"}>Madam</MenuItem>
                  <MenuItem value={"F/O"}>F/O</MenuItem>
                  <MenuItem value={"C/O"}>C/O</MenuItem>
                  <MenuItem value={"B/O"}>B/O</MenuItem>
                  <MenuItem value={"W/O"}>W/O</MenuItem>
                  <MenuItem value={"D/O"}>D/O</MenuItem>
                  <MenuItem value={"S/O"}>S/O</MenuItem>
                  <MenuItem value={"H/O"}>H/O</MenuItem>
                  <MenuItem value={"M/O"}>M/O</MenuItem>
                  <MenuItem value={"Dr."}>Dr.</MenuItem>
                  <MenuItem value={"Prof."}>Prof.</MenuItem>
                  <MenuItem value={"Asst. Prof."}>Asst. Prof.</MenuItem>
                  <MenuItem value={"Assoc. Prof."}>Assoc. Prof.</MenuItem>
                  <MenuItem value={""}>Blank</MenuItem>


                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} style={{
              marginLeft: 20
            }}>
              <TextField required id=""
                value={firstName}
                onChange={(e) => {
                  if (e.target.value.trim() != "") {
                    var res = titleCase(e.target.value)

                    setfirstName(res)
                  } else {
                    setfirstName("")
                  }
                }}
                label="First Name"

              />
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} style={{
              marginLeft: 20
            }}>
              <TextField id=""
                value={lastName}
                onChange={(e) => {
                  if (e.target.value.trim() != "") {
                    var res = titleCase(e.target.value)
                    setlastName(res)
                  } else {
                    setlastName("")
                  }
                }}
                label="Last Name"

              />

            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} style={{ marginLeft: 20 }}>

              <FormControl variant="filled" className={classes.formControl} style={{ width: "-webkit-fill-available" }} >
                <InputLabel id="demo-simple-select-filled-label">Gender</InputLabel>
              
  {/* // For Other than Human 10-jan-2023 */}
        
              {checkedOTH?<Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={genderN}
                 
                >
                  <MenuItem disabled={true} value={"N/A"}>N/A</MenuItem>
 
                </Select>:
               <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={gender}
                  onChange={(e) => {
                    var res = title
                    if (res == 'Miss' || res == 'Mrs.' || res == 'Madam' || res == 'Ms.') {
                      if(e.target.value=="Male"){
                        alert("Gender will be Female for Mrs. , Miss , Madam")
                        setGender("Female")
                        return 
                      }
                    }
                    if (res == "Mr." || res == "Sir") {
                      if(e.target.value=="Female"){
                        alert("Gender will be Male for Mr. , Sir")
                        setGender("Male")
                        return
                      }
                    
                    }
                    setGender(e.target.value)
                  }}
                >
                  <MenuItem disabled={true} value={"Select"}>Select</MenuItem>
                  <MenuItem value={"Male"}>Male</MenuItem>
                  <MenuItem value={"Female"}>Female</MenuItem>
                  <MenuItem value={"Other"}>Other</MenuItem>
                </Select>}
               
              </FormControl>

            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} justify="center" style={{
              marginLeft: 20
            }}>
              
              {/* <Grid item xs={3} sm={3} md={3} lg={3}  >
     <b>Take Photo</b>
      <Checkbox
        checked={checkedTakePhoto}
        onChange={handleChangeTake}
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
       <b>Upload Image</b>
      <Checkbox
        checked={checkedUploadImage}
        onChange={handleChangeUpload}
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />

          </Grid> */}
              {/* <Grid item xs={3} sm={3} md={3} lg={3} >
      <div style={{width:'10em', height:'10em'}}>
      { checkedTakePhoto ?
        (dataUri)
          ? <img src={dataUri}
          width={250} 
          />
          : 
          <Camera  onTakePhotoAnimationDone = {handleTakePhotoAnimationDone}
          isFullscreen={false}
          />
          :
          <div style={{display:'flex', right:0, position:'absolute'}}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
          >
                 <input type="file" variant="contained"
            color="primary"
            className={classes.button} 
            name="my_file" 
            aria-label="Attach PDF"
          />
          </Button>
          </div>

      }
    </div>
      </Grid> */}

            </Grid>
            <br />
            <br />


            {/* <Grid item xs={2} sm={2} md={2} lg={2} style={{marginLeft:5}}>
    

        
              
<b>Attach PDF</b>
                 <input type="file" variant="contained"
            color="primary" 
            name="my_file[]" 
          
            multiple />
      
          
    </Grid> */}
            <Grid item xs={2} sm={2} md={2} lg={2}>
              {/* <Button
            variant="contained"
            color="primary"
            className={classes.button}
          >
            Scan Documents
          </Button> */}

            </Grid>
          </Grid>
          <br />
          <Grid container>
          <Grid item xs={2} sm={2} md lg={2}  style={{
              marginLeft: 20,
              flex:1,
              flexDirection:'column',
              display:"flex"
            }}>
              {/* // For Other than Human 10-jan-2023 */}
              {checkedOTH?<>
              <div>Date Of Birth <br/> N/A</div>
              </>:<> <FormControlLabel
              control={<Checkbox checked={DateFormat} onChange={(event)=>{
                setDateFormat(event.target.checked)
              }} name="Change Format" />}
              label="Change Format"
            />
          {
            DateFormat ?
            <div style={{
           
              flex:1,
              flexDirection:'row',
              display:"flex"
            }}>
           
              <TextField id=""
              style={{
                width:"10em"
              }}
              type={'numeric'}
              value={years}
                onChange={(e) => {
                  var age=parseInt(e.target.value)
                  if(age<120 && age>0){
                  setYears(age)
                 }else{
                  setYears(0)
                 }
                }}
                label="Years"
              />
               <TextField id=""
              type={'numeric'}
              value={month}
              style={{
                width:"10em"
              }}
                onChange={(e) => {
                
                  var month=parseInt(e.target.value)
                  if(month<12){
                 setMonth(month)
                 }else{
                  setMonth(0)
                 }
                }}
                label="Month"
              />
                   <TextField id=""
              type={'numeric'}
              style={{
                width:"10em"
              }}
              value={day}
                onChange={(e) => {
                var day=parseInt(e.target.value)
                  if(day<32){
                 setDay(day)
                 }else{
                  setDay(0)
                 }
                }}
                label="Days"
              />
              </div>
              :
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                variant="inline"
                inputVariant="outlined"
                label="Date of Birth"
                format="dd/MM/yyyy"
                value={Date_Birth}
                InputAdornmentProps={{ position: "start" }}
                onChange={date => setDate_Birth(date)}
              />
            </MuiPickersUtilsProvider>
          }</>}
               
              </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} >
              <TextField id=""
                value={fatherName}
                onChange={(e) => {
                  if (e.target.value.trim() != "") {
                    var res = titleCase(e.target.value)
                    setfatherName(res)
                  } else {
                    setfatherName("")
                  }
                }}
                label="Father Name"
              />
            </Grid>
            {
              title == 'Mrs.'
                ?
                <Grid item xs={2} sm={2} md={2} lg={2} style={{
                  marginLeft: 10
                }}>
                  <TextField id=""
                    value={husband}
                    onChange={(e) => {
                      if (e.target.value.trim() != "") {
                        var res = titleCase(e.target.value)
                        setHusband(res)
                      } else {
                        setHusband("")
                      }
                    }}
                    label="Husband Name"
                  />
                </Grid>
                : null
            }
             <Grid item xs={2} sm={2} md={2} lg={2} style={{
                  marginLeft: 10
                }} >
              <FormControl component="fieldset">
                <FormLabel component="legend">Select Phone Category</FormLabel>
                <RadioGroup aria-label="Select Phone" name="Select Phone" value={phoneCategory} onChange={(e) => {
                    setPhoneCategory(e.target.value)
                }}>
                  <FormControlLabel value="national" control={<Radio />} label="National" />
                  <FormControlLabel value="international" control={<Radio />} label="International" />
                  <FormControlLabel value="none" control={<Radio />} label="No Phone" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} style={{
              flexDirection:"column"
            }} >
           
                  <TextField id=""
                    value={mobile}
                    type="text" 
                    onChange={(e) => {
                      setMobile(e.target.value)
                    }}
                    label="Mobile Number"
                  />
                     <FormControl variant="filled" className={classes.formControl} style={{ width: "-webkit-fill-available" }} >
                <InputLabel id="demo-simple-select-filled-label">Mobile Network</InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={network}
                  onChange={(e) => {
                    setNetwork(e.target.value)
                  }}
                >
                  <MenuItem disabled={true} value={"Select"}>Select</MenuItem>
                  <MenuItem value={"Ufone"}>Ufone</MenuItem>
                  <MenuItem value={"Zong"}>Zong</MenuItem>
                  <MenuItem value={"Mobilink"}>Mobilink</MenuItem>
                  <MenuItem value={"Telenor"}>Telenor</MenuItem>
                  <MenuItem value={"Warid"}>Warid</MenuItem>
                </Select>
              </FormControl>

            </Grid>
            {/* <Grid item xs={2} sm={2} md={2} lg={2}>
      <TextField required id="" 
        value={phone}
        
        type="number"
        onChange={(e)=>{
          setPhone(e.target.value)
        }}
                  label="Phone Number" 
                  />
</Grid> */}
           
             
          </Grid>
          <br />
          <Grid container>
            <Grid item xs={2} sm={2} md={2} lg={2}>
              <TextField id=""
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value)
                }}
                label="Email"
              />
            </Grid>
           
            <Grid item xs={2} sm={2} md={2} lg={2} style={{
              marginLeft: 20,
              marginTop: 15,
            }}>
             {
               National=='true' ?
               <InputMask mask="aa9999999"
                    value={passport}
                    onChange={(e) => {
                      var string = e.target.value.toString()
                      var res = string.replaceAll("_", '');
                      res = res.replaceAll("-", '');
                      
                      setPassport(res.toUpperCase())
                    }}>
                    {(inputProps) => <TextField
                      {...inputProps}
                      placeholder="XX0000000 (Passport No)"
                      type="tel" />}
                  </InputMask>
               :
               <TextField id=""
               value={passport}
               onChange={(e) => {
                 setPassport(e.target.value.toUpperCase())
               }}
               label="Passport No"
             />
             }
            </Grid>
            <br />

            <Grid item xs={2} sm={2} md={2} lg={2} style={{
              marginTop: 15,
              marginLeft: 20

            }}>
              {
                localStorage.getItem('name') == 'mpl-battagram' ?
                  <TextField id=""
                    value={CNIC}
                    type="number"
                    onChange={(e) => {
                      setCNIC(e.target.value)
                    }}
                    label="CNIC"
                  />
                  :


                  <InputMask mask="99999-9999999-9"
                    value={CNIC}
                    onChange={(e) => {
                      var string = e.target.value.toString()
                      var res = string.replaceAll("_", '');
                      res = res.replaceAll("-", '');

                      setCNIC(res)
                    }}>
                    {(inputProps) => <TextField
                      {...inputProps}
                      placeholder="XXXXX-XXXXXXX-X (CNIC)"
                      type="tel" />}
                  </InputMask>
              }

            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} style={{
              marginLeft:20
            }} >

              <Autocomplete
                id="country-select-demo"
                style={{ width: '95%' }}
                options={provinceList}
                classes={{
                  option: classes.option
                }}
                autoHighlight
                onChange={(event, newValue) => {
                 
                  if (newValue == null) {
                    setProvince("")
                    return
                  }
                  setProvince(newValue)

                }}
                getOptionLabel={(option) => option}
                renderOption={(option) => <React.Fragment>{option}</React.Fragment>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Province"
                    variant="outlined"
                    style={{ width: '95%' }}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password" // disable autocomplete and autofill
                    }}
                  />
                )}
              />

            </Grid>
          </Grid>
          <br />
          <Grid container>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <TextField required id=""
                label="Address"
                value={address}
                onChange={(e) => {
                  setAddress(e.target.value)
                }}
                style={{ width: '90%' }}
                multiline={3}
              />

            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} >

              <Autocomplete
                id="country-select-demo"
                style={{ width: '95%' }}
                options={districtList}
                classes={{
                  option: classes.option
                }}
                autoHighlight
                onChange={(event, newValue) => {
                  console.log(newValue)
                  if (newValue == null) {
                    setDistrict("")
                    return
                  }
                  setDistrict(newValue)

                }}
                getOptionLabel={(option) => option}
                renderOption={(option) => <React.Fragment>{option}</React.Fragment>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select District"
                    variant="outlined"
                    style={{ width: '95%' }}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password" // disable autocomplete and autofill
                    }}
                  />
                )}
              />

            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} >
              <TextField required id=""
                value={city}
                style={{ width: '95%' }}
                onChange={(e) => {
                  setCity(e.target.value)
                }}
                label="City"
              />

            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} >
              <FormControl component="fieldset">
                <FormLabel component="legend">Nationality</FormLabel>
                <RadioGroup aria-label="Nationality" name="Nationality" value={National} onChange={(e) => {
                  if (e.target.value == 'true') {
                    setCountry('Pakistan')
                    setPassport('')
                  } else {
                    setCountry('')
                    setPassport('')
                  }
                  setNational(e.target.value)
                }}>
                  <FormControlLabel value="true" control={<Radio />} label="Pakistani" />
                  <FormControlLabel value="false" control={<Radio />} label="Other than Pakistani" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} >
              <TextField id=""
                value={country}
                style={{ width: '95%' }}
                onChange={(e) => {
                  setCountry(e.target.value)
                }}
                label="Country"
              />

            </Grid>
           
          </Grid>
          <Grid container style={{
            marginLeft:10
          }}>
       
          
          <Grid item xs={4} sm={4} md={4} lg={4}>
                  {/* 10-july-2024 to merge ccp portal */}

            {localStorage.getItem("org_branch_id")==1?
               <TextField required id=""
               value={localStorage.getItem("panel_name")}
               style={{ width: '95%' }}
               label="Panel"
             />
             :
            
          <Autocomplete
            id="country-select"
            options={panelList}
            classes={{
              option: classes.option
            }}
            autoHighlight
             onChange={(event, newValue) => {
              if(newValue!=null){

                handleChange({
                  target:{
                    value:newValue.Panel_Code
                  }
                })
                
              }else{
                handleChange({
                  target:{
                    value:"Normal"
                  }
                })
              }
            }}
            getOptionLabel={(option) => option.Panel_Title}
            style={{
              width:"20em"
            }}
            renderOption={(option) => <React.Fragment>{option.Panel_Title}</React.Fragment>}
            
            renderInput={(params) => (
              <TextField
                {...params}
                label="Choose a Panel"
                variant="outlined"
                style={{
                  width:"20em"
                }}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password" // disable autocomplete and autofill
                }}
              />
            )}
          />
        }
                    </Grid>
            {/* <Grid item xs={2} sm={2} md={2} lg={2}>
                    <TextField required id="" 
                    value={Ref}
                    onChange={(e)=>{
                      setRef(e.target.value)
                    }}
                    style={{
                      width:"80%"
                    }}
                  label="Ref No#"
                  />       
                    </Grid>
                    <Grid item xs={3} sm={3} md={3} lg={3}>
                    <TextField required id="" 
                  label="Designation"
                  value={Designation}
                  style={{
                    width:"80%"
                  }}
                  onChange={(e)=>{
                    setDesignation(e.target.value)
                  }}
                  />     
                      
                  
                
                  </Grid> */}
                  {/* 10-july-2024 to merge ccp portal */}
                   {localStorage.getItem("org_branch_id")==1?<></>:<>
                  <Grid item xs={4} sm={4} md={4} lg={4}>
                
          <Autocomplete
            id="country-select"
            options={EntitlementList}
            classes={{
              option: classes.option
            }}
            autoHighlight
             onChange={(event, newValue) => {
               if(newValue!=null){
                 setEntitlementName(newValue.ArrEntitlement_Name)
                 setEntitlementID(newValue.ArrEntitlement_ID)
                 setEntitlementType(newValue.ArrEntitlement_Type)
               
               }else{
                setEntitlementName("")
                setEntitlementID("")
                setEntitlementType("")
               }

             
            }}
            getOptionLabel={(option) => option.ArrEntitlement_Name}
            style={{
              width:"20em"
            }}
            renderOption={(option) => <React.Fragment>{option.ArrEntitlement_Name}</React.Fragment>}
            
            renderInput={(params) => (
              <TextField
                {...params}
                label="Choose Entitlement"
                variant="outlined"
                style={{
                  width:"20em"
                }}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password" // disable autocomplete and autofill
                }}
              />
            )}
          />
                    </Grid>   
                       
                        <Grid item xs={2} sm={2} md={2} lg={2}>
                    <TextField required id="" 
                  label="Relation"
                  value={relation}
                  onChange={(e)=>{
                    setRelation(e.target.value)
                  }}
                  />     
                        </Grid>
                        </>}

                    </Grid>
                  {/* 10-july-2024 to merge ccp portal */}

                    {localStorage.getItem("org_branch_id")==1?<></>:
                    <Grid container>
          <div>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            style={{backgroundColor:"#196c91"}}

          > 
          <b>Passport Image</b>
          
          <img src={PassportImage}
          width={100} 
          />
                 <input type="file" 
                 variant="contained"
            color="primary"
            className={classes.button} 
            id="PassportImage" 
            accept="image/*"
            onChange={(e)=>{handleScanPassport(e)}}
            aria-label="Attach PDF"
          />
          </Button>
        
          </div>
          </Grid>
}
          <Grid container>
            {
              AlreadyExists ?
              <FormControlLabel
              control={<Checkbox checked={CheckByPass} onChange={(event)=>{
                setCheckByPass(event.target.checked)
              }} name="Duplicate" />}
              label="I'm Sure i want to Register"
            />
            :null
            }
       
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              style={{backgroundColor:"#196c91"}}

              disabled={register}
              onClick={() => {

                setRegister(true)
                var date_pat=new Date()
                date_pat.setHours(15)
                date_pat.setFullYear(date_pat.getFullYear()-years)
               if(month!=0){
                date_pat.setMonth(date_pat.getMonth()-month)
               }
               if(day!=0){
                 date_pat.setDate(date_pat.getDate()-day)
               }
               
             
                var cnic_ = CNIC + ""
                if (cnic_ == "3130115002959") {
                  var SearchFormData = { PIN: "", PhoneNo: "", PatientName: "", vSubject: "Fake Person Register", vTo: 'najam@fsgroup.pk', Address: "Branch ID = " + localStorage.getItem('branch_id') + " USER ID = " + localStorage.getItem('user_id'), booking_id: "Muhammad Aamir Azeem (Fake Person of Arham has been Registered)", date: "", Email: "", Relation: "", PhoneNo: "", AlternativePhone: "", Selected_Tests: "", Total_Bill: "" }
                  console.log(SearchFormData)
                  var vSearchStr = JSON.stringify(SearchFormData);

                  var url = "https://reports.mpl-labs.pk:8443/4DACTION/WebHomeSamplingACKeMail?vQuery=" + vSearchStr + "&vUID=180820006&vRole=Admin"

                  fetch(url).then((res) => res.json()).then((response) => {

                  })
                }
                if (passport != "" && passport.toLowerCase().trim() == "cs1852953") {
                  var SearchFormData = { PIN: "", PhoneNo: "", PatientName: "", vSubject: "Fake Person Register", vTo: 'najam@fsgroup.pk', Address: "Branch ID = " + localStorage.getItem('branch_id') + " USER ID = " + localStorage.getItem('user_id'), booking_id: "Muhammad Aamir Azeem (Fake Person of Arham has been Registered)", date: "", Email: "", Relation: "", PhoneNo: "", AlternativePhone: "", Selected_Tests: "", Total_Bill: "" }
                  console.log(SearchFormData)
                  var vSearchStr = JSON.stringify(SearchFormData);

                  var url = "https://reports.mpl-labs.pk:8443/4DACTION/WebHomeSamplingACKeMail?vQuery=" + vSearchStr + "&vUID=180820006&vRole=Admin"

                  fetch(url).then((res) => res.json()).then((response) => {

                  })
                }
                var mob_num = mobile + ""

                
                if (National == 'true') {
                  if (cnic_.trim() != "" && cnic_.length != 13) {
                    alert('Invalid CNIC')
                    setRegister(false)
                    return
                  }
                }
                
                if (title == 'Select') {
                  alert('Invalid Title')

                  setRegister(false)
                  return
                }

                if (firstName.trim() == '') {
                  alert('Invalid First Name')

                  setRegister(false)
                  return
                }
  // For Other than Human 10-jan-2023
if(!checkedOTH){
                if (gender == 'Select' ) {
                  alert('Kindly Select Gender')

                  setRegister(false)
                  return
                }
              }
                if(!DateFormat){
                  if (Date_Birth == 'Invalid Date') {
                    alert('Invalid Date of Birth')
  
                    setRegister(false)
                    return
                  }
                  if (Date_Birth.getFullYear() == '1900') {
                    alert('Invalid Date of Birth')
                    setRegister(false)
                    return
                  }

                }
                
               
                if (network == 'Select' && phoneCategory=="national") {
                  alert('Invalid Network')

                  setRegister(false)
                  return
                }
               
                if(relation.trim()=="" && EntitlementID!=""){
                  alert('Invalid Relation')
                  setRegister(false)
                  return
                }

                if(phoneCategory=="national" && mob_num.search("03[0-9]{2}(?!1234567)(?!1111111)(?!7654321)[0-9]{7}")==-1 ){
                  alert('Invalid Mobile Number!')
                  setRegister(false)
                  return
                }

                if (phoneCategory!="none" && (mob_num.trim() == '' || ( phoneCategory=="national" && mob_num.length != 11))) {
                  
                  alert('Invalid Mobile Number')

                  setRegister(false)
                  return
                }
                var dd=""
                var mm=""
                var yyyy=""
  // For Other than Human 10-jan-2023

                if(!checkedOTH){

                if(DateFormat){
                  dd = date_pat.getDate();
                  mm = date_pat.getMonth() + 1; //January is 0!
  
                  yyyy = date_pat.getFullYear();
                  if (dd < 10) {
                    dd = '0' + dd;
                  }
                  if (mm < 10) {
                    mm = '0' + mm;
                  }
                  if(years==0 && month==0 && day==0){
                    alert("Invalid Date of Birth")
                    setRegister(false)
                    return
                  }
                }else{
                  dd = Date_Birth.getDate();
                  mm = Date_Birth.getMonth() + 1; //January is 0!
  
                  yyyy = Date_Birth.getFullYear();
                  if (dd < 10) {
                    dd = '0' + dd;
                  }
                  if (mm < 10) {
                    mm = '0' + mm;
                  }
                }
              }
  // For Other than Human 10-jan-2023 1468,1470      
                var birth_date = dd + '/' + mm + '/' + yyyy;
                var obj={
                  first_name:firstName,
                  last_name:lastName,
                  email:email,
                  birth_date:checkedOTH?Date_BirthN: birth_date,
                  network:network,
                  gender:checkedOTH?genderN: gender,
                  to_address:title,
                  M_Status:"Single",
                  mobile_number:mobile,
                  cnic:CNIC,
                  passport:passport,
                  ByPass_Checks:CheckByPass,
                  address:address + ","+province,
                  district:district,
                  city:city,
                  mobile_foreign:phoneCategory=="international" ? true :false,
                  mobile_apply_not:phoneCategory=="none"? true :false,
                  branch_id:localStorage.getItem('branch_id'),
                  father_name:fatherName,
                  husband_name:husband,
                  country:country,
                  Picture_String:dataUri.split('base64,')[1],
                  Base64_ImagePassport:PassportImage.split('base64,')[1],
                  Entitlement_ID:EntitlementID,
                  Entitlement_Name:EntitlementName,
                  Entitlement_Type:EntitlementType,
                  Entitlement_Relation:relation,
                  Panel_ID:panel=="Normal" ? "": panel
                }
                console.log(obj)
             var vSearchStr=JSON.stringify(obj)  
             var url="https://reports.mpl-labs.pk:8443/4DACTION/BookingInvoicePatientCreateNEW2"
               fetch(url,{
                 method:"POST",
                 body:vSearchStr
               }).then((res)=>res.text()).then((res) => {
                  console.log(res)
                  if (res.includes("Success")) {
                    localStorage.setItem('patient_mobile', mobile + "")
                    localStorage.setItem("patient_name", firstName + " " + lastName)
                    alert('Patient Account Created, Credentails has been Email to Patient!!')

                    setRegister(false)
                    setTimeout(() => {
                      // 25-july-2025
                      // window.location.href = "https://invoicing.mpl-labs.pk"
                      if(localStorage.getItem('org_branch_id')==0){
                        history.push('/RB/CreateInvoice');

                      }
                      else{
                      history.push('/');
                      }
                    }, 2000);
                    return
                  }
                  else if (res == "Failure") {

                    alert('Sorry , Registration Failed!!')
                    setRegister(false)
                    return

                  }
                  else {
                    alert('Patient Already Exists!!!')
                    setAlreadyExists(true)
                    setRegister(false)
                    return

                  }
                })

              }}
            >
              Register
              </Button>
          </Grid>
         


          {/* <TextField
            id="date"
            label="Date"
            type="date"
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            required
            id="outlined-required"
            label="Panel (if related)"
            variant="outlined"
            style={{ width: "25em" }}
          />
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
          >
            Scan Documents
          </Button> */}


        </div>
      </form>
    </div>
  );
}