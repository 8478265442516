import React, { useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import MaterialTable from 'material-table';

import DateFnsUtils from '@date-io/date-fns';

import { DatePicker, KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import Datetime from 'react-datetime'
import ReactLoading from 'react-loading';

import SearchIcon from '@material-ui/icons/Search';


import { Robo } from '../Invoice/firebase';


import Grid from "@material-ui/core/Grid";


const columns = [
  { label: 'Invoice No', id: 'InvoiceID' },
  { label: 'Status', id: 'status' },
  { label: 'Sample ID', id: 'sampleID' },
  { label: 'Test Code', id: 'code' },
  { label: 'Sample Type', id: 'name' },
  { label: 'Patient Name', id: 'patientName' },
  { label: 'Bottle Color', id: 'bottleColor' }
];
const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 1000,
  },
});
function msToTime(ms) {
  var seconds = (ms / 1000);
  var minutes = parseInt(seconds / 60, 10);
  seconds = seconds % 60;
  var hours = parseInt(minutes / 60, 10);
  minutes = minutes % 60;

  return hours + ':' + minutes;
}


export default function ROBO() {
  const classes = useStyles();
  const [date, setDate] = React.useState(new Date())
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([]);
  const [org_rows, setOrg_Rows] = React.useState([]);
  const [loading, setLoading] = React.useState(true)
  const [branchDetail, setBranchDetail] = React.useState([]);
  const [data_export, setData_Export] = React.useState([])
  const [selectedDate, setSelectedDate] = React.useState(new Date())
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const [branch, setBranch] = React.useState('All')
  const [state, setState] = React.useState({
    status: 'All',
  });

  const formatAMPM=(date)=>{
    var hours = date.getHours();
    var minutes = date.getMinutes()
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes
    return strTime;
  }

  Date.prototype.ddmmyyy = function () {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();

    return [
      (dd > 9 ? '' : '0') + dd,
      (mm > 9 ? '' : '0') + mm,
      this.getFullYear()
    ].join('/');
  };
  const getData = async (data) => {


    var rows_export = []
    var d = date.toISOString()
    var date_ = new Date(d).ddmmyyy()

    var res = await data.map((item) => {
      var obj = [
        { value: item.srno, style: { font: { sz: "14" }, alignment: { horizontal: 'center' } } },
        { value: item.patient_id, style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
        { value: item.invoice_id, style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
        { value: item.name, style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
        { value: item.mobile, style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
        { value: item.booking_date_time, style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
        { value: item.result_date_time, style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
        { value: item.reference_no, style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
        { value: item.flight_no, style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
        { value: item.flight_date, style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
        { value: item.destination, style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
        { value: item.passport, style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
        { value: item.result, style: { font: { sz: "14", color: '#87ceeb' }, alignment: { horizontal: 'center' } } },

      ]
      rows_export.push(obj)

      return

    })

    Promise.all(res).then(() => {
      setData_Export(rows_export)
    })
  }
  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  useEffect(() => {
  
     var tempDate=date.getFullYear()+"-"+(date.getMonth()+1)+"-"+date.getDate()
     console.log("Temp Date Robo 139",tempDate)
                        
     Robo.database().ref("robo/Invoices/"+tempDate).once('value', async (data)=>{
      
        var Invoices=JSON.parse(data.toJSON())
        console.log("before JSON perse",Invoices)
        if(Invoices==null){
          setLoading(false)
          return
        }
        var the_rows=[]
        Invoices.map((item)=>{
        item=JSON.parse(item)
          var samples=JSON.parse(item.samples)
          // console.log("after JSON perse",item)
      // if(item.status=="Updated"){
      //     return
      // }
          var counter=0
          samples.map((sample)=>{
            var test=JSON.parse(item.Test_Codes)[counter]
            var Bottlecolor=JSON.parse(item.roboSlots)[counter]==1 ? "Purple Top" : JSON.parse(item.roboSlots)[counter]==2 ? 'Yellow Top' : JSON.parse(item.roboSlots)[counter]==3 ? 'Blue Top' : JSON.parse(item.roboSlots)[counter]==4 ? 'Green Top' :JSON.parse(item.roboSlots)[counter]==5 ? 'Grey Top' : JSON.parse(item.roboSlots)[counter]==6 ? 'Yellow Top': JSON.parse(item.roboSlots)[counter]==0 ? '':''
            var sample_type=JSON.parse(item.title)[counter]
              var obj={
                  InvoiceID:item.invoiceNo,
                  sampleID:sample,
                  name:JSON.parse(item.title)[counter],
                  patientID:item.id,
                  status:item.status,
                  patientName:item.name,
                  code:item.Test_Codes== undefined ? "": JSON.parse(item.Test_Codes)[counter],
                  bottleColor:JSON.parse(item.roboSlots)[counter]==1 ? "purple" : JSON.parse(item.roboSlots)[counter]==2 ? 'yellow' : JSON.parse(item.roboSlots)[counter]==3 ? 'blue' : JSON.parse(item.roboSlots)[counter]==4 ? 'green' :JSON.parse(item.roboSlots)[counter]==5 ? 'grey' : JSON.parse(item.roboSlots)[counter]==6 ? 'yellow': JSON.parse(item.roboSlots)[counter]==0 ? 'Manual':'' ,
                 // for BCrobo 7 29-DEC-22 (1/3)
                 refrence_no:item.refrence_no,
                 idTag:item.idTag,
                 // for BCrobo 7 17-JAN-23 (1/3)
                 sample_type:sample_type,
                 
                  barcode:
                  <Button variant="contained" style={{ backgroundColor: '#196c91', color:'#ffff'}} key={item.invoiceNo} onClick={()=>{
                    var obj1={
                      sample_id:sample,
                      age_gender:Bottlecolor,
                      test_title:test,
                      date:new Date().ddmmyyy(),
                      mpl_id:item.invoiceNo,
                      name:item.name,
                      patient_id:item.id,
                      time:formatAMPM(new Date()),
                      sample_type:sample_type,
                      // for Barcode 29-DEC-22 (1/2)
                      refrence_no:item.refrence_no!=""? "REF NO : "+item.refrence_no+" | ":"REF NO : "+"Nil   "+" |",
                      idTag:item.idTag

      
                    }
                    console.log(obj1)
                    var url="http://localhost:1000/GETBarcode"
                    fetch(url,{
                      method:"POST",
                      body:JSON.stringify(obj1),
                      headers:{
                        'Content-Type':"application/json"
                      }
                    }).then(()=>{
                      alert("Print Request Sent")
                    })
                  }}>BARCODE</Button>
                  
              }
              counter++;
              the_rows.push(obj)
          })
        })
        setRows(the_rows)
        setLoading(false)
    })
    
  }, [])
  Date.prototype.hhmm = function () {
    var mm = this.getMinutes();
    var hh = this.getHours();

    return [
      (hh > 9 ? '' : '0') + hh,
      (mm > 9 ? '' : '0') + mm
    ].join(':');
  };

  Date.prototype.ddmmyyy = function () {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();

    return [
      (dd > 9 ? '' : '0') + dd,
      (mm > 9 ? '' : '0') + mm,
      this.getFullYear()
    ].join('/');
  };
  return (
    <Paper className={classes.root}>

      <Grid container style={{ padding: '2em' }}>
        <Grid item xs={6} sm={6} md={3} lg={3}>
          
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
<KeyboardDateTimePicker
      variant="inline"
      inputVariant="outlined"
      label="To"
      format="dd/MM/yyyy"
      maxDate={new Date()}
      value={date}
      onChange={date=> setDate(date)}
    />
    </MuiPickersUtilsProvider>
         
        </Grid>



        <Grid item xs={6} sm={6} md={3} lg={3} style={{ margin: 'auto' }} >

          <Button variant="contained" color="primary" disableElevation
              style={{backgroundColor:"#196c91"}}

            onClick={() => {
              var tempDate=date.getFullYear()+"-"+(date.getMonth()+1)+"-"+date.getDate()
                        
              Robo.database().ref("robo/Invoices/"+tempDate).once('value', async (data)=>{
                 var Invoices=JSON.parse(data.toJSON())
                 if(Invoices==null){
                   setLoading(false)
                   return
                 }
                 var the_rows=[]
                 Invoices.map((item)=>{
                 item=JSON.parse(item)
                   var samples=JSON.parse(item.samples)
              //  if(item.status=="Updated"){
              //      return
              //  }
                   var counter=0
                   samples.map((sample)=>{
                    var test=JSON.parse(item.Test_Codes)[counter]
                    var Bottlecolor=JSON.parse(item.roboSlots)[counter]==1 ? "Purple Top" : JSON.parse(item.roboSlots)[counter]==2 ? 'Yellow Top' : JSON.parse(item.roboSlots)[counter]==3 ? 'Blue Top' : JSON.parse(item.roboSlots)[counter]==4 ? 'Green Top' :JSON.parse(item.roboSlots)[counter]==5 ? 'Grey Top' : JSON.parse(item.roboSlots)[counter]==6 ? 'Yellow Top': JSON.parse(item.roboSlots)[counter]==0 ? '':''
                    var sample_type=JSON.parse(item.title)[counter]  
                    var obj={
                           InvoiceID:item.invoiceNo,
                           sampleID:sample,
                           name:JSON.parse(item.title)[counter],
                           patientID:item.id,
                           status:item.status,
                           patientName:item.name,
                           code:item.Test_Codes== undefined ? "": JSON.parse(item.Test_Codes)[counter] ,
                           bottleColor:JSON.parse(item.roboSlots)[counter]==1 ? "purple" : JSON.parse(item.roboSlots)[counter]==2 ? 'yellow' : JSON.parse(item.roboSlots)[counter]==3 ? 'blue' : JSON.parse(item.roboSlots)[counter]==4 ? 'green' :JSON.parse(item.roboSlots)[counter]==5 ? 'grey' : JSON.parse(item.roboSlots)[counter]==6 ? 'yellow': JSON.parse(item.roboSlots)[counter]==0 ? 'Manual':'' ,
                           // for BCrobo 7 29-DEC-22 (2/3)
                           refrence_no:item.refrence_no,
                           idTag:item.idTag,
                            // for BCrobo 7 17-JAN-23 (1/3)
                 sample_type:sample_type,
                           barcode:
                           <Button variant="contained" style={{backgroundColor:'white', color:'black'}} key={item.invoiceNo} onClick={()=>{
                             var obj1={
                               sample_id:sample,
                               age_gender:Bottlecolor,
                               test_title:test,
                               date:new Date().ddmmyyy(),
                               mpl_id:item.invoiceNo,
                               name:item.name,
                               patient_id:item.id,
                               time:formatAMPM(new Date()),
                               sample_type:sample_type,
                                // for Barcode 29-DEC-22 (2/2)
                               refrence_no:item.refrence_no!=""? "REF NO : "+item.refrence_no+" | ":"REF NO : "+"Nil   "+" |",
                               idTag:item.idTag
               
                             }
                             var url="http://localhost:1000/GETBarcode"
                             fetch(url,{
                               method:"POST",
                               body:JSON.stringify(obj1),
                               headers:{
                                 'Content-Type':"application/json"
                               }
                             }).then(()=>{
                               alert("Print Request Sent")
                             })
                           }}>BARCODE</Button>
                           
                       }
                       counter++;
                       the_rows.push(obj)
                   })
                 })
                 setRows(the_rows)
                 setLoading(false)
                })
            }}

          >
            Search<SearchIcon />
          </Button>

        </Grid>
        <Grid items xs={6} sm={6} md={3} lg={3}>

        </Grid>
        <Grid item md={3} lg={3} style={{ margin: 'auto', float: 'right' }} >
       
        </Grid>
      </Grid>

      {loading ?
        <center>
          <ReactLoading type={'spinningBubbles'} color={'black'} height={'10em'} width={'10em'} />
        </center>
        :
        <>
        <MaterialTable
      title="Phlebotomy"
      data={rows}
      
      options={{
        minBodyHeight:1000,
        initialPage:0,
        paging:true,
     pageSize:20,
     pageSizeOptions:[20,30,40],
     selection: true,
     headerStyle: {
      backgroundColor: '#196c91',
      color: '#FFF'
    }
    }}
      columns={[
        { title: 'Invoice No', field: 'InvoiceID' },
  { title: 'Status', field: 'status' },
  { title: 'Sample ID', field: 'sampleID' },
  { title: 'Sample Type', field: 'name' },
  { title: 'Test Code', field: 'code' },
  { title: 'Patient ID', field: 'patientID' },
  { title: 'Patient Name', field: 'patientName' },
  { title: 'Bottle Color', field: 'bottleColor' ,editable: 'never', render: rowData => {
    return <p style={{ backgroundColor: rowData.bottleColor=="Manual" ? "black" : rowData.bottleColor,  fontWeight: "bold", textTransform:'uppercase', textAlign:"center", color:'white' }}>{rowData.bottleColor}</p>   
  }  
},
{ title: 'Barcode', field: 'barcode' },
      ]}
  
      actions={[
        {
          tooltip: 'Request BC ROBO 7 for Tubes',
          //jab koi item select kren ge to ye icon aae ga
          icon: 'update',
          onClick: async(evt, sample) => {
           

             var temp=await sample.map((item)=>{
                 return item.InvoiceID
             })
             Promise.all(temp).then((values)=>{
                var record = values.filter((value,index)=> values.indexOf(value)==index)
               if(record.length>1){
                   alert("Sorry, You can't place order for more than one Invoice at a time!!!")
                   return
               }
                Robo.database().ref("robo").once('value', async (data)=>{
                var Quene_No=data.toJSON().Quene_No
                var UniqueNo=data.toJSON().UniqueNo
              
                var packing=await sample.map((item)=>{
                    return {
                      Terminal_No:"0005",
                      Terminal_HOST:"0001",
                      UniqueNo:UniqueNo+"",
                      Quene_No:Quene_No+"",
                      Sample_Volume:"1.0",
                      Stocker_Code:item.bottleColor=='purple' ? '01' : item.bottleColor=='yellow' ? '02' : item.bottleColor=='blue' ? '03' : item.bottleColor=='green' ? '04' : item.bottleColor=='grey' ? '05' : '99',
                      RFID:"",
                      Priority:"0",
                      Department:"ROBO",
                      Order_Name:item.code,
                      Invoice_No:item.InvoiceID+"",
                      Sample_ID:item.sampleID+"",
                      Patient_ID:item.patientID,
                      // Patient_Name: (item.patientName.includes('Urine') || item.patientName.includes('Semen') || item.patientName.includes('Stool')) ? "" : item.patientName,
                     //17-jan-2023 name na aae jo Urine ka ho ya Semen Ka ho ya Stool ka ho name me muhammad me b aata hai
                      Patient_Name: (item.sample_type.includes('Urine') || item.sample_type.includes('Semen') || item.sample_type.includes('Stool')) ? "" : item.patientName,
                      Barcode:item.sampleID+"", 
                      Logo:"MPL",
                    // for BCrobo 7 29-DEC-22 (3/3)
                    RTitle:"RNO",
                    refrence_no:item.refrence_no!=""?item.refrence_no:"Nil",
                    idTag:item.idTag,
                     // for BCrobo 7 17-JAN-23 (1/3)
                    // sample_type:item.sample_type!=""?item.sample_type:"",
                    }
                    
            })
            // console.log("Packing final send to ProvideSampleBottles ",packing)
            Promise.all(packing).then((values)=>{
                fetch("http://localhost:1200/ProvideSampleBottles",{
                    method:"POST",
                    headers:{
                        "Content-Type":"application/json"
                    },
                    body:JSON.stringify(values)
                }).then((response)=>{
            var tempDate=date.getFullYear()+"-"+(date.getMonth()+1)+"-"+date.getDate()
                         
             Robo.database().ref("robo/Invoices/"+tempDate).once('value', async (data)=>{
                 var Invoices=JSON.parse(data.toJSON())
                 var the_rows=[]
 
                 Invoices.map((item)=>{
                 item=JSON.parse(item)
                 if(record.includes(item.invoiceNo)){
                     item.status="Updated"
                 }
                 
                 the_rows.push(JSON.stringify(item))
                 })
                 console.log(the_rows)
                 Invoices=JSON.stringify(the_rows)
                Robo.database().ref("robo/Invoices/"+tempDate).set(Invoices)
                alert("You Request has been successfully sent!!")
                window.location.reload()
             })
           
                })

           
               
            })
              
              })
             
                })
             
           
          }
          
        }
      ]}
      
      
    />
        </>
      }
    </Paper>
  );
}
