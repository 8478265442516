// import React, { Fragment } from 'react';
// import { Text, View, StyleSheet, Font } from '@react-pdf/renderer';
// const borderColor = '#90e5fc'
// const styles = StyleSheet.create({
//     row: {
//         flexDirection: 'row',
//         borderBottomColor: '#bf',
//         borderBottomWidth: 1,
//         fontFamily: 'Helvetica',
//         alignItems: 'center',
//         height: 150,
//         fontStyle: 'bold',
//     },
//     description: {
//         width: '60%',
//         textAlign: 'left',
//         borderRightColor: borderColor,
//         borderRightWidth: 1,
//         paddingLeft: 8,

//     },
//     qty: {
//         width: '10%',
//         borderRightColor: borderColor,
//         borderRightWidth: 1,
//         textAlign: 'right',
//         paddingRight: 8,
//     },
//     rate: {
//         width: '15%',
//         borderRightColor: borderColor,
//         borderRightWidth: 1,
//         textAlign: 'right',
//         paddingRight: 8,
//     },
//     amount: {
//         width: '15%',
//         textAlign: 'right',
//         paddingRight: 8,
//     },
// });


// const InvoiceTableRow = ({ items, discount , panel,checkedCAG }) => {
//     var rows = []
//     var total_bill = 0
//     var counter = 0
//     var total_discount = 0

//     console.log("InvoiceTableRows items pass from props :")
//     console.log(items)
//     console.log("InvoiceTableRows discount pass from props :")
//     console.log(discount)
//     console.log("InvoiceTableRows Panel pass from props :")
//     console.log(panel)
   
//     if(panel){
//         items.map(item => {
//             total_bill = total_bill + parseInt(item.Actual_Price)
//             total_discount = total_discount + parseInt(item.Actual_Price - item.Test_Price)
          
//             counter = counter + 1
//             return rows.push(<View style={{
    
//                 flexDirection: 'row',
//                 borderTopColor: 'BLACK',
//                 borderTopWidth: 1,
//                 alignItems: 'center',
//                 width: "100%",
//                 fontStyle: 'bold'
//             }} key={item.Test_Name}>
//                 <Text style={{
    
//                     // width: '5%',
//                     width: '3%',

//                     textAlign: 'left',
//                     borderLeftColor: 'black',
//                     borderLeftWidth: 1,
//                     // paddingLeft: 8,
//                     paddingLeft: 4,

//                     height: '100%',
    
    
//                 }}>{counter}</Text>
    
//                 <Text style={{
    
//                     // width: '20%',
//                     width: '18%',

//                     textAlign: 'left',
//                     borderLeftColor: 'black',
//                     borderLeftWidth: 1,
//                     // paddingLeft: 8,
//                     paddingLeft: 5,

//                     height: '100%',
    
    
//                 }}>{item.Test_Name}</Text>
//                 <Text style={{
    
//                     // width: '10%',
//                     width: '8%',

//                     textAlign: 'center',
//                     borderLeftColor: 'black',
//                     borderLeftWidth: 1,
//                     height: '100%',
//                     // paddingLeft: 8
//                     paddingLeft: 2

    
    
//                 }}>{item.Test_Code}</Text>
//                 <Text style={{
    
//                     // width: '25%',
//                     width: '18%',

//                     textAlign: 'left',
//                     borderLeftColor: 'black',
//                     borderLeftWidth: 1,
//                     // paddingLeft: 8,
//                     paddingLeft: 2.5,
//                     paddingRight:2.5,
//                     height: '100%'
    
    
//                 }}>
//                     {/* {item.Sample_Type} */}
//                     {item.Test_Name.toLowerCase().includes("x-ray")?"Patient to visit lab":item.Test_Name.toLowerCase().includes("ultrasound")?"Patient to visit lab through appointment":item.Sample_Type}

//                     </Text>
//                     <Text style={{
    
//     width: '15.5%',
    
//     borderLeftColor: 'black',
//     borderLeftWidth: 1,
//     // paddingLeft: 5,
//     paddingLeft:item.special_comments?2.5: 38.5,
//     paddingRight:2.5,
//     height: '100%',
//     // paddingRight:2
//     textAlign: 'left',


// }}>
    
//     {item.special_comments?item.special_comments:"N/A"}
   
// </Text>
//                 <Text style={{
    
//                     // width: '15%',
//                     width: '12.5%',

//                     textAlign: 'left',
//                     borderLeftColor: 'black',
//                     borderLeftWidth: 1,
//                     height: '100%',
//                     // paddingLeft: 5,
//                     paddingLeft: 2,

    
//                 }}>{item.Delivery_Time}</Text>
//                 <Text style={{
    
//                     width: '8.3%',
//                     textAlign: 'center',
//                     borderLeftColor: 'black',
//                     borderLeftWidth: 1,
//                     height: '100%',
//                     paddingLeft: 5,
    
    
//                 }}>{"Rs " + parseFloat(item.Actual_Price).toFixed(2) + "/-"}</Text>
//                 <Text style={{
    
//                     width: '8.3%',
//                     textAlign: 'center',
//                     borderLeftColor: 'black',
//                     borderLeftWidth: 1,
//                     height: '100%',
//                     paddingLeft: 5,
    
    
//                 }}>{"Rs " + (parseFloat(item.Actual_Price - item.Test_Price)).toFixed(2) + "/-"}</Text>
//                 <Text style={{
    
//                     width: '8.3%',
//                     textAlign: 'center',
//                     borderLeftColor: 'black',
//                     borderRightWidth: 1,
//                     borderLeftWidth: 1,
//                     height: '100%',
//                     paddingLeft: 5,
    
    
//                 }}>{"Rs " + (parseFloat(item.Test_Price)).toFixed(2) + "/-"}</Text>
    
//             </View>)
//         }
//         )
//         if (items.length < 1000) {
//             rows.push(<View>
//                 <View style={{
    
//                     flexDirection: 'row',
//                     alignItems: 'center',
//                     width: "100%",
//                     borderTopWidth: 0.5,
//                     borderTopColor: 'black',
//                     fontStyle: 'bold'
//                 }} key={"test_1"}>
//                     <Text style={{
    
//                         width: '30%',
//                         textAlign: 'left',
//                         paddingLeft: 8,
//                         height: '100%',
    
    
//                     }}></Text>
//                     <Text style={{
    
//                         width: '10%',
//                         textAlign: 'center',
//                         height: '100%',
//                         paddingLeft: 8
    
    
//                     }}></Text>
//                     <Text style={{
    
//                         width: '35%',
//                         textAlign: 'left',
//                         paddingLeft: 8,
//                         height: '100%'
    
    
//                     }}></Text>
//                     <Text style={{
    
//                         width: '12.5%',
//                         height: '100%',
    
    
//                         padding: 3,
//                         borderWidth: 0.5,
//                         fontFamily: 'Helvetica-Bold',
//                         fontSize: 8,
//                         textAlign: 'left',
//                         color: 'black',
    
    
    
//                     }}>Total Amount</Text>
//                     <Text style={{
    
//                         width: '12.5%',
//                         height: '100%',
//                         borderWidth: 0.5,
    
//                         padding: 3,
//                         fontFamily: 'Helvetica-Bold',
//                         fontSize: 8,
//                         textAlign: 'right',
//                         color: 'black',
    
    
    
//                     }}>{"Rs " + parseFloat(total_bill).toFixed(2) + "/-"}</Text>
    
//                 </View>
//                 {/* ////////////////////////////////////////////////////////////////////////////////////// */}
//                 <View style={{
    
//                     flexDirection: 'row',
//                     alignItems: 'center',
//                     width: "100%",
//                     marginTop: 5,
//                     fontStyle: 'bold'
//                 }} key={"test_1"}>
//                     <Text style={{
    
//                         width: '30%',
//                         textAlign: 'left',
//                         paddingLeft: 8,
//                         height: '100%',
    
    
//                     }}></Text>
//                     <Text style={{
    
//                         width: '10%',
//                         textAlign: 'center',
//                         height: '100%',
//                         paddingLeft: 8
    
    
//                     }}></Text>
//                     <Text style={{
    
//                         width: '35%',
//                         textAlign: 'left',
//                         paddingLeft: 8,
//                         height: '100%'
    
    
//                     }}></Text>
//                     <Text style={{
    
//                         width: '12.5%',
//                         height: '100%',
//                         borderWidth: 0.5,
    
//                         padding: 3,
//                         fontFamily: 'Helvetica-Bold',
//                         fontSize: 8,
//                         textAlign: 'left',
//                         color: 'black',
    
    
    
//                     }}>Total Discount</Text>
//                     <Text style={{
    
//                         width: '12.5%',
//                         height: '100%',
//                         borderWidth: 0.5,
    
    
//                         padding: 3,
//                         fontFamily: 'Helvetica-Bold',
//                         fontSize: 8,
//                         textAlign: 'right',
//                         color: 'black',
    
    
//                     }}>{"Rs " + parseFloat(total_discount).toFixed(2) + "/-"}</Text>
    
//                 </View>
//                 {/* ////////////////////////////////////////////////////////////////////////////////////// */}
//                 <View style={{
    
//                     flexDirection: 'row',
//                     alignItems: 'center',
//                     width: "100%",
//                     fontStyle: 'bold',
//                     marginTop: 5
//                 }} key={"test_1"}>
//                     <Text style={{
    
//                         width: '30%',
//                         textAlign: 'left',
//                         paddingLeft: 8,
//                         height: '100%',
    
    
//                     }}></Text>
//                     <Text style={{
    
//                         width: '10%',
//                         textAlign: 'center',
//                         height: '100%',
//                         paddingLeft: 8
    
    
//                     }}></Text>
//                     <Text style={{
    
//                         width: '35%',
//                         textAlign: 'left',
//                         paddingLeft: 8,
//                         height: '100%'
    
    
//                     }}></Text>
//                     <Text style={{
    
//                         width: '12.5%',
//                         height: '100%',
    
    
    
//                         padding: 3,
//                         fontFamily: 'Helvetica-Bold',
//                         fontSize: 8,
//                         textAlign: 'left',
//                         color: 'black',
//                         borderWidth: 0.5,
    
    
    
//                     }}>Net Value</Text>
//                     <Text style={{
    
//                         width: '12.5%',
//                         height: '100%',
    
//                         borderWidth: 0.5,
    
    
//                         padding: 3,
//                         fontFamily: 'Helvetica-Bold',
//                         fontSize: 8,
//                         textAlign: 'right',
//                         color: 'black',
    
    
    
//                     }}>{"Rs " + (parseFloat(total_bill) - parseFloat(total_discount)).toFixed(2) + "/-"}</Text>
    
//                 </View>
    
    
//             </View>
//             )
//         } else {
//             rows.push(<View style={{
    
//                 flexDirection: 'row',
//                 alignItems: 'center',
//                 width: "100%",
//                 borderTopWidth: 1,
//                 borderTopColor: 'black',
//                 fontStyle: 'bold'
//             }} key={"test_1"}>
//                 <Text style={{
    
//                     width: '100%',
//                     textAlign: 'left',
//                     paddingLeft: 8,
//                     height: '100%',
    
    
//                 }}></Text>
//             </View>)
//         }

//     }else{

//         items.map(item => {
        
//             total_bill = total_bill + parseInt(item.Test_Price)
//             counter = counter + 1
//             return rows.push(<View style={{
    
//                 flexDirection: 'row',
//                 borderTopColor: 'BLACK',
//                 borderTopWidth: 1,
//                 alignItems: 'center',
//                 width: "100%",
//                 fontStyle: 'bold'
//             }} key={item.Test_Name}>
//                 <Text style={{
    
//                     // width: '5%',
//                     width: '3%',

//                     textAlign: 'left',
//                     borderLeftColor: 'black',
//                     borderLeftWidth: 1,
//                     // paddingLeft: 8,
//                     paddingLeft: 4,

//                     height: '100%',
    
    
//                 }}>{counter}</Text>
    
//                 <Text style={{
    
//                     // width: '20%',
//                     width: '18%',

//                     textAlign: 'left',
//                     borderLeftColor: 'black',
//                     borderLeftWidth: 1,
//                     // paddingLeft: 8,
//                     paddingLeft: 5,

//                     height: '100%',
    
    
//                 }}>
//                     {item.Test_Name}</Text>
//                 <Text style={{
    
//                     // width: '10%',
//                     width: '8%',

//                     textAlign: 'center',
//                     borderLeftColor: 'black',
//                     borderLeftWidth: 1,
//                     height: '100%',
//                     // paddingLeft: 8
//                     paddingLeft: 2

    
    
//                 }}>{item.Test_Code}</Text>
//                 <Text style={{
    
//                     // width: '25%',
//                     width: '18%',

//                     textAlign: 'left',
//                     borderLeftColor: 'black',
//                     borderLeftWidth: 1,
//                     // paddingLeft: 8,
//                     paddingLeft: 2.5,
//                     paddingRight:2.5,
//                     height: '100%'
    
    
//                 }}>
//                     {/* {item.Sample_Type} */}
//                     {item.Test_Name.toLowerCase().includes("x-ray")?"Patient to visit lab":item.Test_Name.toLowerCase().includes("ultrasound")?"Patient to visit lab through appointment":item.Sample_Type}

//                     </Text>
//                     <Text style={{
    
//     width: '15.5%',
    
//     borderLeftColor: 'black',
//     borderLeftWidth: 1,
//     // paddingLeft: 5,
//     paddingLeft:item.special_comments?2.5: 38.5,
//     paddingRight:2.5,
//     height: '100%',
//     // paddingRight:2
//     textAlign: 'left',


// }}>
    
//     {item.special_comments?item.special_comments:"N/A"}
   
// </Text>
//                 <Text style={{
    
//                     // width: '15%',
//                     width: '12.5%',

//                     textAlign: 'left',
//                     borderLeftColor: 'black',
//                     borderLeftWidth: 1,
//                     height: '100%',
//                     // paddingLeft: 5,
//                     paddingLeft: 2,

    
//                 }}>{item.Delivery_Time}</Text>
//                 <Text style={{
    
//                     width: '8.3%',
//                     textAlign: 'center',
//                     borderLeftColor: 'black',
//                     borderLeftWidth: 1,
//                     height: '100%',
//                     paddingLeft: 5,
    
    
//                 }}>{"Rs " + parseFloat(item.Test_Price).toFixed(2) + "/-"}</Text>
//                 <Text style={{
    
//                     width: '8.3%',
//                     textAlign: 'center',
//                     borderLeftColor: 'black',
//                     borderLeftWidth: 1,
//                     height: '100%',
//                     paddingLeft: 5,
    
    
//                 }}>{"Rs " + ((parseFloat(item.Test_Price) / 100) * discount).toFixed(2) + "/-"}</Text>
//                 <Text style={{
    
//                     width: '8.3%',
//                     textAlign: 'center',
//                     borderLeftColor: 'black',
//                     borderRightWidth: 1,
//                     borderLeftWidth: 1,
//                     height: '100%',
//                     paddingLeft: 5,
    
    
//                 }}>{"Rs " + (parseFloat(item.Test_Price) - (parseFloat(item.Test_Price) / 100) * discount).toFixed(2) + "/-"}</Text>
    
//             </View>)
//         }
//         )
//         if (items.length < 1000) {
//             rows.push(<View>
//                 <View style={{
    
//                     flexDirection: 'row',
//                     alignItems: 'center',
//                     width: "100%",
//                     borderTopWidth: 0.5,
//                     borderTopColor: 'black',
//                     fontStyle: 'bold'
//                 }} key={"test_1"}>
//                     <Text style={{
    
//                         width: '30%',
//                         textAlign: 'left',
//                         paddingLeft: 8,
//                         height: '100%',
    
    
//                     }}></Text>
//                     <Text style={{
    
//                         width: '10%',
//                         textAlign: 'center',
//                         height: '100%',
//                         paddingLeft: 8
    
    
//                     }}></Text>
//                     <Text style={{
    
//                         width: '35%',
//                         textAlign: 'left',
//                         paddingLeft: 8,
//                         height: '100%'
    
    
//                     }}></Text>
//                     <Text style={{
    
//                         width: '12.5%',
//                         height: '100%',
    
    
//                         padding: 3,
//                         borderWidth: 0.5,
//                         fontFamily: 'Helvetica-Bold',
//                         fontSize: 8,
//                         textAlign: 'left',
//                         color: 'black',
    
    
    
//                     }}>Total Amount</Text>
//                     <Text style={{
    
//                         width: '12.5%',
//                         height: '100%',
//                         borderWidth: 0.5,
    
//                         padding: 3,
//                         fontFamily: 'Helvetica-Bold',
//                         fontSize: 8,
//                         textAlign: 'right',
//                         color: 'black',
    
    
    
//                     }}>{"Rs " + parseFloat(total_bill).toFixed(2) + "/-"}</Text>
    
//                 </View>
//                 {/* ////////////////////////////////////////////////////////////////////////////////////// */}
//                 <View style={{
    
//                     flexDirection: 'row',
//                     alignItems: 'center',
//                     width: "100%",
//                     marginTop: 5,
//                     fontStyle: 'bold'
//                 }} key={"test_1"}>
//                     <Text style={{
    
//                         width: '30%',
//                         textAlign: 'left',
//                         paddingLeft: 8,
//                         height: '100%',
    
    
//                     }}></Text>
//                     <Text style={{
    
//                         width: '10%',
//                         textAlign: 'center',
//                         height: '100%',
//                         paddingLeft: 8
    
    
//                     }}></Text>
//                     <Text style={{
    
//                         width: '35%',
//                         textAlign: 'left',
//                         paddingLeft: 8,
//                         height: '100%'
    
    
//                     }}></Text>
//                     <Text style={{
    
//                         width: '12.5%',
//                         height: '100%',
//                         borderWidth: 0.5,
    
//                         padding: 3,
//                         fontFamily: 'Helvetica-Bold',
//                         fontSize: 8,
//                         textAlign: 'left',
//                         color: 'black',
    
    
    
//                     }}>Total Discount</Text>
//                     <Text style={{
    
//                         width: '12.5%',
//                         height: '100%',
//                         borderWidth: 0.5,
    
    
//                         padding: 3,
//                         fontFamily: 'Helvetica-Bold',
//                         fontSize: 8,
//                         textAlign: 'right',
//                         color: 'black',
    
    
    
//                     }}>{"Rs " + ((parseFloat(total_bill) / 100) * discount).toFixed(2) + "/-"}</Text>
    
//                 </View>
//                 {/* ////////////////////////////////////////////////////////////////////////////////////// */}
//                 <View style={{
    
//                     flexDirection: 'row',
//                     alignItems: 'center',
//                     width: "100%",
//                     fontStyle: 'bold',
//                     marginTop: 5
//                 }} key={"test_1"}>
//                     <Text style={{
    
//                         width: '30%',
//                         textAlign: 'left',
//                         paddingLeft: 8,
//                         height: '100%',
    
    
//                     }}></Text>
//                     <Text style={{
    
//                         width: '10%',
//                         textAlign: 'center',
//                         height: '100%',
//                         paddingLeft: 8
    
    
//                     }}></Text>
//                     <Text style={{
    
//                         width: '35%',
//                         textAlign: 'left',
//                         paddingLeft: 8,
//                         height: '100%'
    
    
//                     }}></Text>
//                     <Text style={{
    
//                         width: '12.5%',
//                         height: '100%',
    
    
    
//                         padding: 3,
//                         fontFamily: 'Helvetica-Bold',
//                         fontSize: 8,
//                         textAlign: 'left',
//                         color: 'black',
//                         borderWidth: 0.5,
    
    
    
//                     }}>Net Value</Text>
//                     <Text style={{
    
//                         width: '12.5%',
//                         height: '100%',
    
//                         borderWidth: 0.5,
    
    
//                         padding: 3,
//                         fontFamily: 'Helvetica-Bold',
//                         fontSize: 8,
//                         textAlign: 'right',
//                         color: 'black',
    
    
    
//                     }}>{"Rs " + (parseFloat(total_bill) - (parseFloat(total_bill) / 100) * discount).toFixed(2) + "/-"}</Text>
    
//                 </View>
    
    
//             </View>
//             )
//         } else {
//             rows.push(<View style={{
    
//                 flexDirection: 'row',
//                 alignItems: 'center',
//                 width: "100%",
//                 borderTopWidth: 1,
//                 borderTopColor: 'black',
//                 fontStyle: 'bold'
//             }} key={"test_1"}>
//                 <Text style={{
    
//                     width: '100%',
//                     textAlign: 'left',
//                     paddingLeft: 8,
//                     height: '100%',
    
    
//                 }}></Text>
//             </View>)
//         }
//     }




//     return (<Fragment>{rows}</Fragment>)
// };

// export default InvoiceTableRow

import React, { Fragment } from 'react';
import { Text, View, StyleSheet, Font } from '@react-pdf/renderer';
const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#bf',
        borderBottomWidth: 1,
        fontFamily: 'Helvetica',
        alignItems: 'center',
        height: 150,
        fontStyle: 'bold',
    },
    description: {
        width: '60%',
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,

    },
    qty: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    rate: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    amount: {
        width: '15%',
        textAlign: 'right',
        paddingRight: 8,
    },
});


const InvoiceTableRow = ({ items, discount , panel,comment ,groupName,checkedCAG}) => {
    var rows = []
    var total_bill = 0
    var counter = 0
    var total_discount = 0

    console.log("InvoiceTableRows items pass from props :")
    console.log(items)
    console.log("InvoiceTableRows discount pass from props :")
    console.log(discount)
    console.log("InvoiceTableRows Panel pass from props :")
    console.log(panel)
    console.log("InvoiceTableRows Comment for testing pass from props :")
    console.log(comment)
   
    if(panel){
        items.map(item => {
            total_bill = total_bill + parseInt(item.Actual_Price)
            total_discount = total_discount + parseInt(item.Actual_Price - item.Test_Price)
          
            counter = counter + 1
            return rows.push(<View style={{
    
                flexDirection: 'row',
                borderTopColor: 'BLACK',
                borderTopWidth: 1,
                alignItems: 'center',
                width: "100%",
                fontStyle: 'bold'
            }} key={item.Test_Name}>
                <Text style={{
    
                    // width: '5%',
                    // textAlign: 'left',
                    // borderLeftColor: 'black',
                    // borderLeftWidth: 1,
                    // paddingLeft: 8,
                    // height: '100%',
                    width: '3%',
                    textAlign: 'left',
                    borderLeftColor: 'black',
                    borderLeftWidth: 1,
                    paddingLeft: 6,
                    height: '100%',
                    
    
    
                }}>{counter}</Text>
    
                <Text style={{
    
                    // width: '20%',
                    // textAlign: 'left',
                    // borderLeftColor: 'black',
                    // borderLeftWidth: 1,
                    // paddingLeft: 8,
                    // height: '100%',
                    width: '18%',
                    // width: '15%',


                    textAlign: 'left',
                    borderLeftColor: 'black',
                    borderLeftWidth: 1,
                    paddingLeft: 5,
                    height: '100%',
                    // backgroundColor:"red"
    
    
                }}>{item.Test_Name}</Text>
                <Text style={{
    
                    // width: '10%',
                    // textAlign: 'center',
                    // borderLeftColor: 'black',
                    // borderLeftWidth: 1,
                    // height: '100%',
                    // paddingLeft: 8
                    width: '8%',
                    // width: '7%',

                    textAlign: 'center',
                    borderLeftColor: 'black',
                    borderLeftWidth: 1,
                    height: '100%',
                    paddingLeft: 2
    
    
                }}>{item.Test_Code}</Text>
                <Text style={{
    
                    // width: '25%',
                    // textAlign: 'left',
                    // borderLeftColor: 'black',
                    // borderLeftWidth: 1,
                    // paddingLeft: 8,
                    // height: '100%'
                    width: '18%',
                    // width: '27%',

                    
                    borderLeftColor: 'black',
                    borderLeftWidth: 1,
    // paddingLeft: 5,

                    paddingLeft: 2.5,
                    paddingRight:2.5,
                    height: '100%',
                    // paddingRight:2
                    textAlign: 'left',
    
    
                }}>
                    {/* {item.Sample_Type} */}
                    {item.Test_Name.toLowerCase().includes("x-ray")?"Patient to visit lab":item.Test_Name.toLowerCase().includes("ultrasound")?"Patient to visit lab through appointment":item.Sample_Type}

                    </Text>
                 <Text style={{
    
    width: '15.5%',
    // width: '12.5%',

    
    borderLeftColor: 'black',
    borderLeftWidth: 1,
    // paddingLeft: 5,
    paddingLeft:item.special_comments?2.5: 38.5,
    paddingRight:2.5,
    height: '100%',
    // paddingRight:2
    textAlign: 'left',


}}>
    
    {item.special_comments?item.special_comments:"N/A"}
   
</Text>
                <Text style={{
    
                    // width: '15%',
                    // textAlign: 'left',
                    // borderLeftColor: 'black',
                    // borderLeftWidth: 1,
                    // height: '100%',
                    // paddingLeft: 5,
                    width: '12.5%',
                    // width: '11.5%',

                    textAlign: 'left',
                    borderLeftColor: 'black',
                    borderLeftWidth: 1,
                    height: '100%',
                    paddingLeft: 2,
    
                }}>{item.Delivery_Time}</Text>
                <Text style={{
    
                    width: '8.3%',
                    // width: '7.3%',

                    textAlign: 'center',
                    borderLeftColor: 'black',
                    borderLeftWidth: 1,
                    height: '100%',
                    paddingLeft: 5,
    
    
                }}>{"Rs " + parseFloat(item.Actual_Price).toFixed(2) + "/-"}</Text>
                <Text style={{
    
                    width: '8.3%',
                    // width: '7.3%',

                    textAlign: 'center',
                    borderLeftColor: 'black',
                    borderLeftWidth: 1,
                    height: '100%',
                    paddingLeft: 5,
    
    
                }}>{"Rs " + (parseFloat(item.Actual_Price - item.Test_Price)).toFixed(2) + "/-"}</Text>
                <Text style={{
    
                    width: '8.3%',
                    // width: '9.3%',

                    textAlign: 'center',
                    borderLeftColor: 'black',
                    borderRightWidth: 1,
                    borderLeftWidth: 1,
                    height: '100%',
                    paddingLeft: 5,
    
    
                }}>{"Rs " + (parseFloat(item.Test_Price)).toFixed(2) + "/-"}</Text>
    
            </View>)
        }
        )
        if (items.length < 1000) {
            rows.push(<View>
                <View style={{
    
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: "100%",
                    borderTopWidth: 0.5,
                    borderTopColor: 'black',
                    fontStyle: 'bold'
                }} key={"test_1"}>
                    <Text style={{
    
                        width: '30%',
                        textAlign: 'left',
                        paddingLeft: 8,
                        height: '100%',
    
    
                    }}></Text>
                    <Text style={{
    
                        width: '10%',
                        textAlign: 'center',
                        height: '100%',
                        paddingLeft: 8
    
    
                    }}></Text>
                    <Text style={{
    
                        width: '35%',
                        textAlign: 'left',
                        paddingLeft: 8,
                        height: '100%'
    
    
                    }}></Text>
                    <Text style={{
    
                        width: '12.5%',
                        height: '100%',
    
    
                        padding: 3,
                        borderWidth: 0.5,
                        fontFamily: 'Helvetica-Bold',
                        fontSize: 8,
                        textAlign: 'left',
                        color: 'black',
    
    
    
                    }}>Total Amount</Text>
                    <Text style={{
    
                        width: '12.5%',
                        height: '100%',
                        borderWidth: 0.5,
    
                        padding: 3,
                        fontFamily: 'Helvetica-Bold',
                        fontSize: 8,
                        textAlign: 'right',
                        color: 'black',
    
    
    
                    }}>{"Rs " + parseFloat(total_bill).toFixed(2) + "/-"}</Text>
    
                </View>
                {/* ////////////////////////////////////////////////////////////////////////////////////// */}
                <View style={{
    
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: "100%",
                    marginTop: 5,
                    fontStyle: 'bold'
                }} key={"test_1"}>
                    <Text style={{
    
                        width: '30%',
                        textAlign: 'left',
                        paddingLeft: 8,
                        height: '100%',
    
    
                    }}></Text>
                    <Text style={{
    
                        width: '10%',
                        textAlign: 'center',
                        height: '100%',
                        paddingLeft: 8
    
    
                    }}></Text>
                    <Text style={{
    
                        width: '35%',
                        textAlign: 'left',
                        paddingLeft: 8,
                        height: '100%'
    
    
                    }}></Text>
                    <Text style={{
    
                        width: '12.5%',
                        height: '100%',
                        borderWidth: 0.5,
    
                        padding: 3,
                        fontFamily: 'Helvetica-Bold',
                        fontSize: 8,
                        textAlign: 'left',
                        color: 'black',
    
    
    
                    }}>Total Discount</Text>
                    <Text style={{
    
                        width: '12.5%',
                        height: '100%',
                        borderWidth: 0.5,
    
    
                        padding: 3,
                        fontFamily: 'Helvetica-Bold',
                        fontSize: 8,
                        textAlign: 'right',
                        color: 'black',
    
    
                    }}>{"Rs " + parseFloat(total_discount).toFixed(2) + "/-"}</Text>
    
                </View>
                {/* ////////////////////////////////////////////////////////////////////////////////////// */}
                <View style={{
    
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: "100%",
                    fontStyle: 'bold',
                    marginTop: 5
                }} key={"test_1"}>
                    <Text style={{
    
                        width: '30%',
                        textAlign: 'left',
                        paddingLeft: 8,
                        height: '100%',
    
    
                    }}></Text>
                    <Text style={{
    
                        width: '10%',
                        textAlign: 'center',
                        height: '100%',
                        paddingLeft: 8
    
    
                    }}></Text>
                    <Text style={{
    
                        width: '35%',
                        textAlign: 'left',
                        paddingLeft: 8,
                        height: '100%'
    
    
                    }}></Text>
                    <Text style={{
    
                        width: '12.5%',
                        height: '100%',
    
    
    
                        padding: 3,
                        fontFamily: 'Helvetica-Bold',
                        fontSize: 8,
                        textAlign: 'left',
                        color: 'black',
                        borderWidth: 0.5,
    
    
    
                    }}>Net Value</Text>
                    <Text style={{
    
                        width: '12.5%',
                        height: '100%',
    
                        borderWidth: 0.5,
    
    
                        padding: 3,
                        fontFamily: 'Helvetica-Bold',
                        fontSize: 8,
                        textAlign: 'right',
                        color: 'black',
    
    
    
                    }}>{"Rs " + (parseFloat(total_bill) - parseFloat(total_discount)).toFixed(2) + "/-"}</Text>
    
                </View>
    
    
            </View>
            )
        } else {
            rows.push(<View style={{
    
                flexDirection: 'row',
                alignItems: 'center',
                width: "100%",
                borderTopWidth: 1,
                borderTopColor: 'black',
                fontStyle: 'bold'
            }} key={"test_1"}>
                <Text style={{
    
                    width: '100%',
                    textAlign: 'left',
                    paddingLeft: 8,
                    height: '100%',
    
    
                }}></Text>
            </View>)
        }

    }else if((groupName) && (!checkedCAG)){
        console.log("me yhan hun")
        items.map(item => {
            total_bill = total_bill + parseInt(item.Actual_Price)
            total_discount = total_discount + parseInt(item.Actual_Price - item.Test_Price)
          
            counter = counter + 1
            return rows.push(<View style={{
    
                flexDirection: 'row',
                // borderTopColor: 'BLACK',
                borderTopWidth: 1,
                alignItems: 'center',
                width: "100%",
                fontStyle: 'bold'
            }} key={item.Test_Name}>
                <Text style={{
    
                    // width: '5%',
                    // textAlign: 'left',
                    // borderLeftColor: 'black',
                    // borderLeftWidth: 1,
                    // paddingLeft: 8,
                    // height: '100%',
                    width: '3%',
                    textAlign: 'left',
                    borderLeftColor: 'black',
                    borderLeftWidth: 1,
                    paddingLeft: 6,
                    height: '100%',
                    
    
    
                }}>{counter}</Text>
    
                <Text style={{
    
                    // width: '20%',
                    // textAlign: 'left',
                    // borderLeftColor: 'black',
                    // borderLeftWidth: 1,
                    // paddingLeft: 8,
                    // height: '100%',
                    width: '18%',
                    // width: '15%',


                    textAlign: 'left',
                    borderLeftColor: 'black',
                    borderLeftWidth: 1,
                    paddingLeft: 5,
                    height: '100%',
                    // backgroundColor:"red"
    
    
                }}>{item.Test_Name}</Text>
                <Text style={{
    
                    // width: '10%',
                    // textAlign: 'center',
                    // borderLeftColor: 'black',
                    // borderLeftWidth: 1,
                    // height: '100%',
                    // paddingLeft: 8
                    width: '8%',
                    // width: '7%',

                    textAlign: 'center',
                    borderLeftColor: 'black',
                    borderLeftWidth: 1,
                    height: '100%',
                    paddingLeft: 2
    
    
                }}>{item.Test_Code}</Text>
                <Text style={{
    
                    // width: '25%',
                    // textAlign: 'left',
                    // borderLeftColor: 'black',
                    // borderLeftWidth: 1,
                    // paddingLeft: 8,
                    // height: '100%'
                    width: '18%',
                    // width: '27%',

                    
                    borderLeftColor: 'black',
                    borderLeftWidth: 1,
    // paddingLeft: 5,

                    paddingLeft: 2.5,
                    paddingRight:2.5,
                    height: '100%',
                    // paddingRight:2
                    textAlign: 'left',
    
    
                }}>
                    {/* {item.Sample_Type} */}
                    {item.Test_Name.toLowerCase().includes("x-ray")?"Patient to visit lab":item.Test_Name.toLowerCase().includes("ultrasound")?"Patient to visit lab through appointment":item.Sample_Type}

                    </Text>
                 <Text style={{
    
    width: '15.5%',
    // width: '12.5%',

    
    borderLeftColor: 'black',
    borderLeftWidth: 1,
    // paddingLeft: 5,
    paddingLeft:item.special_comments?2.5: 38.5,
    paddingRight:2.5,
    height: '100%',
    // paddingRight:2
    textAlign: 'left',


}}>
    
    {item.special_comments?item.special_comments:"N/A"}
   
</Text>
                <Text style={{
    
                    // width: '15%',
                    // textAlign: 'left',
                    // borderLeftColor: 'black',
                    // borderLeftWidth: 1,
                    // height: '100%',
                    // paddingLeft: 5,
                    width: '12.5%',
                    // width: '11.5%',

                    textAlign: 'left',
                    borderLeftColor: 'black',
                    borderLeftWidth: 1,
                    height: '100%',
                    paddingLeft: 2,
    
                }}>{item.Delivery_Time}</Text>
                <Text style={{
    
                    width: '8.3%',
                    // width: '7.3%',

                    textAlign: 'center',
                    borderLeftColor: 'black',
                    borderLeftWidth: 1,
                    height: '100%',
                    paddingLeft: 5,
    
    
                }}>{"Rs " + parseFloat(item.Actual_Price).toFixed(2) + "/-"}</Text>
                <Text style={{
    
                    width: '8.3%',
                    // width: '7.3%',

                    textAlign: 'center',
                    borderLeftColor: 'black',
                    borderLeftWidth: 1,
                    height: '100%',
                    paddingLeft: 5,
    
    
                }}>{"Rs " + (parseFloat(item.Actual_Price - item.Test_Price)).toFixed(2) + "/-"}</Text>
                <Text style={{
    
                    width: '8.3%',
                    // width: '9.3%',

                    textAlign: 'center',
                    borderLeftColor: 'black',
                    borderRightWidth: 1,
                    borderLeftWidth: 1,
                    height: '100%',
                    paddingLeft: 5,
    
    
                }}>{"Rs " + (parseFloat(item.Test_Price)).toFixed(2) + "/-"}</Text>
    
            </View>)
        }
        )
        if (items.length < 1000) {
            rows.push(<View>
                <View style={{
    
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: "100%",
                    borderTopWidth: 0.5,
                    borderTopColor: 'black',
                    fontStyle: 'bold'
                }} key={"test_1"}>
                    <Text style={{
    
                        width: '30%',
                        textAlign: 'left',
                        paddingLeft: 8,
                        height: '100%',
    
    
                    }}></Text>
                    <Text style={{
    
                        width: '10%',
                        textAlign: 'center',
                        height: '100%',
                        paddingLeft: 8
    
    
                    }}></Text>
                    <Text style={{
    
                        width: '35%',
                        textAlign: 'left',
                        paddingLeft: 8,
                        height: '100%'
    
    
                    }}></Text>
                    <Text style={{
    
                        width: '12.5%',
                        height: '100%',
    
    
                        padding: 3,
                        borderWidth: 0.5,
                        fontFamily: 'Helvetica-Bold',
                        fontSize: 8,
                        textAlign: 'left',
                        color: 'black',
    
    
    
                    }}>Total Amount</Text>
                    <Text style={{
    
                        width: '12.5%',
                        height: '100%',
                        borderWidth: 0.5,
    
                        padding: 3,
                        fontFamily: 'Helvetica-Bold',
                        fontSize: 8,
                        textAlign: 'right',
                        color: 'black',
    
    
    
                    }}>{"Rs " + parseFloat(total_bill).toFixed(2) + "/-"}</Text>
    
                </View>
                {/* ////////////////////////////////////////////////////////////////////////////////////// */}
                <View style={{
    
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: "100%",
                    marginTop: 5,
                    fontStyle: 'bold'
                }} key={"test_1"}>
                    <Text style={{
    
                        width: '30%',
                        textAlign: 'left',
                        paddingLeft: 8,
                        height: '100%',
    
    
                    }}></Text>
                    <Text style={{
    
                        width: '10%',
                        textAlign: 'center',
                        height: '100%',
                        paddingLeft: 8
    
    
                    }}></Text>
                    <Text style={{
    
                        width: '35%',
                        textAlign: 'left',
                        paddingLeft: 8,
                        height: '100%'
    
    
                    }}></Text>
                    <Text style={{
    
                        width: '12.5%',
                        height: '100%',
                        borderWidth: 0.5,
    
                        padding: 3,
                        fontFamily: 'Helvetica-Bold',
                        fontSize: 8,
                        textAlign: 'left',
                        color: 'black',
    
    
    
                    }}>Total Discount</Text>
                    <Text style={{
    
                        width: '12.5%',
                        height: '100%',
                        borderWidth: 0.5,
    
    
                        padding: 3,
                        fontFamily: 'Helvetica-Bold',
                        fontSize: 8,
                        textAlign: 'right',
                        color: 'black',
    
    
                    }}>{"Rs " + parseFloat(total_discount).toFixed(2) + "/-"}</Text>
    
                </View>
                {/* ////////////////////////////////////////////////////////////////////////////////////// */}
                <View style={{
    
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: "100%",
                    fontStyle: 'bold',
                    marginTop: 5
                }} key={"test_1"}>
                    <Text style={{
    
                        width: '30%',
                        textAlign: 'left',
                        paddingLeft: 8,
                        height: '100%',
    
    
                    }}></Text>
                    <Text style={{
    
                        width: '10%',
                        textAlign: 'center',
                        height: '100%',
                        paddingLeft: 8
    
    
                    }}></Text>
                    <Text style={{
    
                        width: '35%',
                        textAlign: 'left',
                        paddingLeft: 8,
                        height: '100%'
    
    
                    }}></Text>
                    <Text style={{
    
                        width: '12.5%',
                        height: '100%',
    
    
    
                        padding: 3,
                        fontFamily: 'Helvetica-Bold',
                        fontSize: 8,
                        textAlign: 'left',
                        color: 'black',
                        borderWidth: 0.5,
    
    
    
                    }}>Net Value</Text>
                    <Text style={{
    
                        width: '12.5%',
                        height: '100%',
    
                        borderWidth: 0.5,
    
    
                        padding: 3,
                        fontFamily: 'Helvetica-Bold',
                        fontSize: 8,
                        textAlign: 'right',
                        color: 'black',
    
    
    
                    }}>{"Rs " + (parseFloat(total_bill) - parseFloat(total_discount)).toFixed(2) + "/-"}</Text>
    
                </View>
    
    
            </View>
            )
        } else {
            rows.push(<View style={{
    
                flexDirection: 'row',
                alignItems: 'center',
                width: "100%",
                borderTopWidth: 1,
                borderTopColor: 'black',
                fontStyle: 'bold'
            }} key={"test_1"}>
                <Text style={{
    
                    width: '100%',
                    textAlign: 'left',
                    paddingLeft: 8,
                    height: '100%',
    
    
                }}></Text>
            </View>)
        }
    } 
    // 22-may-2024 for quoatation group same as invoice
    else if((groupName) && (checkedCAG)){
        console.log("me yhan hun checkCAG")
        console.log("me yhan hun")
        console.log(items)
        items.map((item ,index) => {
            total_bill = total_bill + parseInt(item.Actual_Price)
            total_discount = total_discount + parseInt(item.Actual_Price - item.Test_Price)})
        items.map((item ,index) => {
          
          
            counter = counter + 1
            if(index==0){
            return rows.push(<View style={{
    
                flexDirection: 'row',
                // borderTopColor: 'BLACK',
                borderTopWidth: 1,
                alignItems: 'center',
                width: "100%",
                fontStyle: 'bold'
            }} key={item.Test_Name}>
                <Text style={{
    
                    // width: '5%',
                    // textAlign: 'left',
                    // borderLeftColor: 'black',
                    // borderLeftWidth: 1,
                    // paddingLeft: 8,
                    // height: '100%',
                    width: '3%',
                    textAlign: 'left',
                    borderLeftColor: 'black',
                    borderLeftWidth: 1,
                    paddingLeft: 6,
                    height: '100%',
                    
    
    
                }}>{counter}</Text>
    
                <Text style={{
    
                    // width: '20%',
                    // textAlign: 'left',
                    // borderLeftColor: 'black',
                    // borderLeftWidth: 1,
                    // paddingLeft: 8,
                    // height: '100%',
                    // width: '18%',
                    width: '35%',


                    textAlign: 'left',
                    borderLeftColor: 'black',
                    borderLeftWidth: 1,
                    paddingLeft: 5,
                    height: '100%',
                    // backgroundColor:"red"
    
    
                }}>
                    <Text style={{ fontFamily: 'Helvetica-Bold'}}>{groupName}</Text>
                    {items.map(itemnew => {
                  return "\n"+ "-"+ itemnew.Test_Name

                    })}
                    </Text>
                <Text style={{
    
                    // width: '10%',
                    // textAlign: 'center',
                    // borderLeftColor: 'black',
                    // borderLeftWidth: 1,
                    // height: '100%',
                    // paddingLeft: 8
                    // width: '8%',
                    width: '10%',

                    textAlign: 'left',
                    borderLeftColor: 'black',
                    borderLeftWidth: 1,
                    height: '100%',
                    paddingLeft: 5
    
    
                }}>
                    {/* {item.Test_Code} */}
                    {items.map(itemnew => {
                  return "\n"+ itemnew.Test_Code

                    })}
                   
                    </Text>
                {/* <Text style={{
    
                    // width: '25%',
                    // textAlign: 'left',
                    // borderLeftColor: 'black',
                    // borderLeftWidth: 1,
                    // paddingLeft: 8,
                    // height: '100%'
                    // width: '18%',
                    width: '27%',

                    
                    borderLeftColor: 'black',
                    borderLeftWidth: 1,
    // paddingLeft: 5,

                    paddingLeft: 2.5,
                    paddingRight:2.5,
                    height: '100%',
                    // paddingRight:2
                    textAlign: 'left',
    
    
                }}>
                 
                    {item.Test_Name.toLowerCase().includes("x-ray")?"Patient to visit lab":item.Test_Name.toLowerCase().includes("ultrasound")?"Patient to visit lab through appointment":item.Sample_Type}

                    </Text> */}
                 {/* <Text style={{
    
    // width: '15.5%',
    width: '12.5%',

    
    borderLeftColor: 'black',
    borderLeftWidth: 1,
    // paddingLeft: 5,
    paddingLeft:item.special_comments?2.5: 38.5,
    paddingRight:2.5,
    height: '100%',
    // paddingRight:2
    textAlign: 'left',


}}>
    
    {item.special_comments?item.special_comments:"N/A"}
   
</Text> */}
                <Text style={{
    
                    // width: '15%',
                    // textAlign: 'left',
                    // borderLeftColor: 'black',
                    // borderLeftWidth: 1,
                    // height: '100%',
                    // paddingLeft: 5,
                    // width: '12.5%',
                    width: '24%',

                    textAlign: 'left',
                    borderLeftColor: 'black',
                    borderLeftWidth: 1,
                    height: '100%',
                    paddingLeft: 5,
    
                }}>
                    {/* {item.Delivery_Time} */}
                
                    {items.map(itemnew => {
                  return "\n"+  (itemnew.Delivery_Time==""?"N/A":itemnew.Delivery_Time)

                    })}
                    </Text>
                <Text style={{
    
                    // width: '8.3%',
                    width: '10%',

                    textAlign: 'center',
                    borderLeftColor: 'black',
                    borderLeftWidth: 1,
                    height: '100%',
                    paddingLeft: 2,
                    paddingTop:10
    
    
                }}>{"Rs " + parseFloat(total_bill).toFixed(2) + "/-"}</Text>
                <Text style={{
    
                    // width: '8.3%',
                    width: '8.5%',

                    textAlign: 'center',
                    borderLeftColor: 'black',
                    borderLeftWidth: 1,
                    height: '100%',
                    paddingLeft: 2,
                    paddingTop:10
    
                }}>{"Rs " + (parseFloat(total_discount)).toFixed(2) + "/-"}</Text>
                <Text style={{
    
                    // width: '8.3%',
                    width: '9.5%',

                    textAlign: 'center',
                    borderLeftColor: 'black',
                    borderRightWidth: 1,
                    borderLeftWidth: 1,
                    height: '100%',
                    paddingLeft: 2,
                    paddingTop:10
    
    
                }}>{"Rs " + (parseFloat(total_bill) - parseFloat(total_discount)).toFixed(2) + "/-"}</Text>
    
            </View>)
        }
    }
        )
        if (items.length < 1000) {
            rows.push(<View>
                <View style={{
    
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: "100%",
                    borderTopWidth: 0.5,
                    borderTopColor: 'black',
                    fontStyle: 'bold'
                }} key={"test_1"}>
                    <Text style={{
    
                        width: '30%',
                        textAlign: 'left',
                        paddingLeft: 8,
                        height: '100%',
    
    
                    }}></Text>
                    <Text style={{
    
                        width: '10%',
                        textAlign: 'center',
                        height: '100%',
                        paddingLeft: 8
    
    
                    }}></Text>
                    <Text style={{
    
                        width: '32%',
                        textAlign: 'left',
                        paddingLeft: 8,
                        height: '100%'
    
    
                    }}></Text>
                    <Text style={{
    
                        width: '14%',
                        height: '100%',
    
    
                        padding: 3,
                        borderWidth: 0.5,
                        fontFamily: 'Helvetica-Bold',
                        fontSize: 8,
                        textAlign: 'left',
                        color: 'black',
    
    
    
                    }}>Total Amount</Text>
                    <Text style={{
    
                        width: '14%',
                        height: '100%',
                        borderWidth: 0.5,
    
                        padding: 3,
                        fontFamily: 'Helvetica-Bold',
                        fontSize: 8,
                        textAlign: 'right',
                        color: 'black',
    
    
    
                    }}>{"Rs " + parseFloat(total_bill).toFixed(2) + "/-"}</Text>
    
                </View>
                {/* ////////////////////////////////////////////////////////////////////////////////////// */}
                <View style={{
    
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: "100%",
                    marginTop: 5,
                    fontStyle: 'bold'
                }} key={"test_1"}>
                    <Text style={{
    
                        width: '30%',
                        textAlign: 'left',
                        paddingLeft: 8,
                        height: '100%',
    
    
                    }}></Text>
                    <Text style={{
    
                        width: '10%',
                        textAlign: 'center',
                        height: '100%',
                        paddingLeft: 8
    
    
                    }}></Text>
                    <Text style={{
    
                        width: '32%',
                        textAlign: 'left',
                        paddingLeft: 8,
                        height: '100%'
    
    
                    }}></Text>
                    <Text style={{
    
                        width: '14%',
                        height: '100%',
                        borderWidth: 0.5,
    
                        padding: 3,
                        fontFamily: 'Helvetica-Bold',
                        fontSize: 8,
                        textAlign: 'left',
                        color: 'black',
    
    
    
                    }}>Total Discount</Text>
                    <Text style={{
    
                        width: '14%',
                        height: '100%',
                        borderWidth: 0.5,
    
    
                        padding: 3,
                        fontFamily: 'Helvetica-Bold',
                        fontSize: 8,
                        textAlign: 'right',
                        color: 'black',
    
    
                    }}>{"Rs " + parseFloat(total_discount).toFixed(2) + "/-"}</Text>
    
                </View>
                {/* ////////////////////////////////////////////////////////////////////////////////////// */}
                <View style={{
    
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: "100%",
                    fontStyle: 'bold',
                    marginTop: 5
                }} key={"test_1"}>
                    <Text style={{
    
                        width: '30%',
                        textAlign: 'left',
                        paddingLeft: 8,
                        height: '100%',
    
    
                    }}></Text>
                    <Text style={{
    
                        width: '10%',
                        textAlign: 'center',
                        height: '100%',
                        paddingLeft: 8
    
    
                    }}></Text>
                    <Text style={{
    
                        width: '32%',
                        textAlign: 'left',
                        paddingLeft: 8,
                        height: '100%'
    
    
                    }}></Text>
                    <Text style={{
    
                        width: '14%',
                        height: '100%',
    
    
    
                        padding: 3,
                        fontFamily: 'Helvetica-Bold',
                        fontSize: 8,
                        textAlign: 'left',
                        color: 'black',
                        borderWidth: 0.5,
    
    
    
                    }}>Net Value</Text>
                    <Text style={{
    
                        width: '14%',
                        height: '100%',
    
                        borderWidth: 0.5,
    
    
                        padding: 3,
                        fontFamily: 'Helvetica-Bold',
                        fontSize: 8,
                        textAlign: 'right',
                        color: 'black',
    
    
    
                    }}>{"Rs " + (parseFloat(total_bill) - parseFloat(total_discount)).toFixed(2) + "/-"}</Text>
    
                </View>
    
    
            </View>
            )
        } else {
            rows.push(<View style={{
    
                flexDirection: 'row',
                alignItems: 'center',
                width: "100%",
                borderTopWidth: 1,
                borderTopColor: 'black',
                fontStyle: 'bold'
            }} key={"test_1"}>
                <Text style={{
    
                    width: '100%',
                    textAlign: 'left',
                    paddingLeft: 8,
                    height: '100%',
    
    
                }}></Text>
            </View>)
        }
    }
    else{

        items.map(item => {
        
            total_bill = total_bill + parseInt(item.Test_Price)
            counter = counter + 1
            return rows.push(<View style={{
    
                flexDirection: 'row',
                borderTopColor: 'BLACK',
                borderTopWidth: 1,
                alignItems: 'center',
                width: "100%",
                fontStyle: 'bold'
            }} key={item.Test_Name}>
                <Text style={{
    
                    width: '3%',
                    textAlign: 'left',
                    borderLeftColor: 'black',
                    borderLeftWidth: 1,
                    paddingLeft: 6,
                    height: '100%',
    
    
                }}>{counter}</Text>
    
                <Text style={{
    
                    width: '18%',
                    // width: '15%',

                    textAlign: 'left',
                    borderLeftColor: 'black',
                    borderLeftWidth: 1,
                    paddingLeft: 5,
                    height: '100%',
    
    
                }}>{item.Test_Name}</Text>
                <Text style={{
    
                    width: '8%',
                    // width: '7%',

                    textAlign: 'center',
                    borderLeftColor: 'black',
                    borderLeftWidth: 1,
                    height: '100%',
                    paddingLeft: 2
    
    
                }}>{item.Test_Code}</Text>
                <Text style={{
    
                    // width: '27%',
                    width: '18%',
                    
                    borderLeftColor: 'black',
                    borderLeftWidth: 1,
    // paddingLeft: 5,

                    paddingLeft: 2.5,
                    paddingRight:2.5,
                    height: '100%',
                    // paddingRight:2
                    textAlign: 'left',
    
    
                }}>

                    {/* {item.Test_Name.toLowerCase().includes("x-ray")?"Patient to visit lab":item.Test_Name.toLowerCase().includes("ultrasound")?"Patient to visit lab through appointment":item.Sample_Type.replace("\r","\n")} */}
                    {item.Test_Name.toLowerCase().includes("x-ray")?"Patient to visit lab":item.Test_Name.toLowerCase().includes("ultrasound")?"Patient to visit lab through appointment":item.Sample_Type.replace(new RegExp("\r", 'g'), "\n")}

                    {/* Following things are needed for Hydrogen Breath test for Lactose Intolerance,12 Hours Fasting.{"\n"}Prior Appointment.\rThis test has a duration of approx. 3.5 hours (210 minutes). \rDo not take antibiotics for 30 days prior to the test.\rNo enema applications (bowel lavage or preparation for endoscopy) in the 5 days prior to the test. */}
                    {/* <Text style={{}}>{item.Sample_Type} */}
                  
                    {/* {comment&&
                <>
                
                <Text style={{
    fontFamily: 'Helvetica-Bold',
   
}}><Text style={{color:"white"}}>-</Text>Special Remarks 
                </Text>
                <Text style={{color:"white"}}>-</Text>{comment}
               </>} */}
                {/* </Text> */}
                
              

                </Text>
                <Text style={{
    
    // width: '12.5%',
    width: '15.5%',

    
    borderLeftColor: 'black',
    borderLeftWidth: 1,
    // paddingLeft: 5,
    paddingLeft:item.special_comments?2.5: 38.5,
    paddingRight:2.5,
    height: '100%',
    // paddingRight:2
    textAlign: 'left',


}}>
    
    {item.special_comments?item.special_comments:"N/A"}
   
</Text>

                <Text style={{
    
                    width: '12.5%',
                    // width: '11.5%',

                    textAlign: 'left',
                    borderLeftColor: 'black',
                    borderLeftWidth: 1,
                    height: '100%',
                    paddingLeft: 2,
    
                }}>{item.Delivery_Time}</Text>
                <Text style={{
    
                    // width: '7.3%',
                    width: '8.3%',

                    textAlign: 'center',
                    borderLeftColor: 'black',
                    borderLeftWidth: 1,
                    height: '100%',
                    paddingLeft: 5,
    
    
                }}>{"Rs " + parseFloat(item.Test_Price).toFixed(2) + "/-"}</Text>
                <Text style={{
                    width: '8.3%',
    
                    // width: '7.3%',
                    textAlign: 'center',
                    borderLeftColor: 'black',
                    borderLeftWidth: 1,
                    height: '100%',
                    paddingLeft: 5,
    
    
                }}>{"Rs " + ((parseFloat(item.Test_Price) / 100) * discount).toFixed(2) + "/-"}</Text>
                <Text style={{
                    width: '8.3%',
    
                    // width: '7.3%',
                    // width: '9.3%',

                    textAlign: 'center',
                    borderLeftColor: 'black',
                    borderRightWidth: 1,
                    borderLeftWidth: 1,
                    height: '100%',
                    paddingLeft: 5,
    
    
                }}>{"Rs " + (parseFloat(item.Test_Price) - (parseFloat(item.Test_Price) / 100) * discount).toFixed(2) + "/-"}</Text>
    
            </View>)
        }
        )
        if (items.length < 1000) {
            rows.push(<View>
                <View style={{
    
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: "100%",
                    borderTopWidth: 0.5,
                    borderTopColor: 'black',
                    fontStyle: 'bold'
                }} key={"test_1"}>
                    <Text style={{
    
                        width: '30%',
                        textAlign: 'left',
                        paddingLeft: 8,
                        height: '100%',
    
    
                    }}></Text>
                    <Text style={{
    
                        width: '10%',
                        textAlign: 'center',
                        height: '100%',
                        paddingLeft: 8
    
    
                    }}></Text>
                    <Text style={{
    
                        width: '35%',
                        textAlign: 'left',
                        paddingLeft: 8,
                        height: '100%'
    
    
                    }}></Text>
                    <Text style={{
    
                        width: '12.5%',
                        height: '100%',
    
    
                        padding: 3,
                        borderWidth: 0.5,
                        fontFamily: 'Helvetica-Bold',
                        fontSize: 8,
                        textAlign: 'left',
                        color: 'black',
    
    
    
                    }}>Total Amount</Text>
                    <Text style={{
    
                        width: '12.5%',
                        height: '100%',
                        borderWidth: 0.5,
    
                        padding: 3,
                        fontFamily: 'Helvetica-Bold',
                        fontSize: 8,
                        textAlign: 'right',
                        color: 'black',
    
    
    
                    }}>{"Rs " + parseFloat(total_bill).toFixed(2) + "/-"}</Text>
    
                </View>
                {/* ////////////////////////////////////////////////////////////////////////////////////// */}
                <View style={{
    
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: "100%",
                    marginTop: 5,
                    fontStyle: 'bold'
                }} key={"test_1"}>
                    <Text style={{
    
                        width: '30%',
                        textAlign: 'left',
                        paddingLeft: 8,
                        height: '100%',
    
    
                    }}></Text>
                    <Text style={{
    
                        width: '10%',
                        textAlign: 'center',
                        height: '100%',
                        paddingLeft: 8
    
    
                    }}></Text>
                    <Text style={{
    
                        width: '35%',
                        textAlign: 'left',
                        paddingLeft: 8,
                        height: '100%'
    
    
                    }}></Text>
                    <Text style={{
    
                        width: '12.5%',
                        height: '100%',
                        borderWidth: 0.5,
    
                        padding: 3,
                        fontFamily: 'Helvetica-Bold',
                        fontSize: 8,
                        textAlign: 'left',
                        color: 'black',
    
    
    
                    }}>Total Discount</Text>
                    <Text style={{
    
                        width: '12.5%',
                        height: '100%',
                        borderWidth: 0.5,
    
    
                        padding: 3,
                        fontFamily: 'Helvetica-Bold',
                        fontSize: 8,
                        textAlign: 'right',
                        color: 'black',
    
    
    
                    }}>{"Rs " + ((parseFloat(total_bill) / 100) * discount).toFixed(2) + "/-"}</Text>
    
                </View>
                {/* ////////////////////////////////////////////////////////////////////////////////////// */}
                <View style={{
    
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: "100%",
                    fontStyle: 'bold',
                    marginTop: 5
                }} key={"test_1"}>
                    <Text style={{
    
                        width: '30%',
                        textAlign: 'left',
                        paddingLeft: 8,
                        height: '100%',
    
    
                    }}></Text>
                    <Text style={{
    
                        width: '10%',
                        textAlign: 'center',
                        height: '100%',
                        paddingLeft: 8
    
    
                    }}></Text>
                    <Text style={{
    
                        width: '35%',
                        textAlign: 'left',
                        paddingLeft: 8,
                        height: '100%'
    
    
                    }}></Text>
                    <Text style={{
    
                        width: '12.5%',
                        height: '100%',
    
    
    
                        padding: 3,
                        fontFamily: 'Helvetica-Bold',
                        fontSize: 8,
                        textAlign: 'left',
                        color: 'black',
                        borderWidth: 0.5,
    
    
    
                    }}>Net Value</Text>
                    <Text style={{
    
                        width: '12.5%',
                        height: '100%',
    
                        borderWidth: 0.5,
    
    
                        padding: 3,
                        fontFamily: 'Helvetica-Bold',
                        fontSize: 8,
                        textAlign: 'right',
                        color: 'black',
    
    
    
                    }}>{"Rs " + (parseFloat(total_bill) - (parseFloat(total_bill) / 100) * discount).toFixed(2) + "/-"}</Text>
    
                </View>
    
    
            </View>
            )
        } else {
            rows.push(<View style={{
    
                flexDirection: 'row',
                alignItems: 'center',
                width: "100%",
                borderTopWidth: 1,
                borderTopColor: 'black',
                fontStyle: 'bold'
            }} key={"test_1"}>
                <Text style={{
    
                    width: '100%',
                    textAlign: 'left',
                    paddingLeft: 8,
                    height: '100%',
    
    
                }}></Text>
            </View>)
        }
    }




    return (<Fragment>{rows}</Fragment>)
};

export default InvoiceTableRow