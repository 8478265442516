import React, { Fragment } from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import Invoice from '../../Invoice/invoice';
import    InvoiceTableHeader from './InvoiceTableHeader'




const InvoiceTableRow = ({ items ,invoice}) => {
    var counter=0
    const rows = items.map(item =>{

        
            counter++
    
            return (
                <View>
        {/* { counter%9==0
            ?
                 <View style={ {
                    flexDirection: 'row',
                    alignItems: 'center',
                    width:"100%",
                    height:"120em"
                    }} key={item.test_title} >
                        </View>
                        :null
            } */}
            {/* {
                counter%9==0 ?
                <InvoiceTableHeader />
                : null
            } */}

        <View key={item.sn + "321323"}>
            <View style={{
                flexDirection: 'row',
                borderBottomColor: 'BLACK',
                borderBottomWidth: 0.25,
                borderTopWidth: 0.25,
                alignItems: 'center',
                width: "100%",
                fontSize: 7,
                fontStyle: 'bold'
            }} key={item.sn}>
                <Text style={{

                    width: '5%',
                    textAlign: 'left',
                    borderLeftColor: 'black',
                    borderLeftWidth: 0.5,
                    height: '100%',
                    padding: 5,
                    fontSize: 7,
                }}>{item.sn}</Text>
                 {/* <Text style={{

width: '50%',
fontFamily: 'Helvetica-Bold',
textAlign: 'left',
borderLeftColor: 'black',
borderLeftWidth: 0.5,
fontSize: 6,
height: '100%',
padding: 5


}}>{item.test_title}</Text> */}
    {/* // this is for row adding by sir najam waiting for sir kamran 10-jan-2023 and change in LabTest  1096 and 2077 */}
                <View style={{
// 21-jan-23
width: '60%',
// width: '50%',
// fontFamily: 'Helvetica-Bold',
// textAlign: 'left',
borderLeftColor: 'black',
borderLeftWidth: 0.5,
// fontSize: 6,
height: '100%',
padding: 5


}}>
                <Text style={{

                    // width: '100%',
                    fontFamily: 'Helvetica-Bold',
                    textAlign: 'left',
                    // borderLeftColor: 'black',
                    // borderLeftWidth: 0.5,
                    fontSize: 6,
                    // height: '100%',
                    // padding: 5


                }}>{item.test_title}</Text>
                  <Text style={{

// width: '25%',
// fontFamily: 'Helvetica-Bold',
textAlign: 'left',
// borderLeftColor: 'black',
// borderLeftWidth: 0.5,
fontSize: 6,
// height: '100%',
// padding: 5


}}>{item.tests_line_details}</Text>
</View>
<Text style={{
                    //21-jan-23
                    // width: '40%',
                    width: '15%',
                    textAlign: 'center',
                    // textAlign: 'left',
                    borderColor: 'black',
                    borderLeftWidth: 0.5,
                    // borderRightWidth: 0.5,
                    fontSize: 6,
                    padding: 5,
                    height: '100%'


                }}>{item.sample_type?item.sample_type:"N/A"}</Text>
                <Text style={{
                    //21-jan-23
                    // width: '40%',
                    width: '20%',
                    textAlign: 'center',
                    // textAlign: 'left',
                    borderColor: 'black',
                    borderLeftWidth: 0.5,
                    borderRightWidth: 0.5,
                    fontSize: 6,
                    padding: 5,
                    height: '100%'


                }}>{item.reporting_date}</Text>

            </View>
         



        </View>
        </View>
    )
            })
    return (<Fragment>{rows}</Fragment>)
};

export default InvoiceTableRow