import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
   
    titleContainer:{
        flexDirection: 'column',
        marginTop: 24,
    },
    reportTitle:{
        color: 'black',
        fontSize: 9,
        fontFamily: 'Helvetica-Bold',
        textAlign: 'left',
      // 05-sep-2023 2801882 thora sa up kiya now remove bcz nasir zaman ka thora opr aa rha hai baqi thk hn
        // marginTop:5
    },
    PrintTitle:{
      color: 'black',
      fontSize: 9,
      textAlign: 'left',
  },
    ServiceTitle:{
      color: 'black',
      fontSize: 8,
      fontFamily: 'Helvetica-Bold',
      textAlign: 'left',
      marginTop:-10
      // 22-aug-2023 (1.2) 0201898 es invoice pe ye changes ki thi but update ni kiya
      
      // marginTop:-7
  },
    addressTitle:{
      color: 'black',
      fontSize: 7,
      textAlign: 'left',
     // 05-sep-2023 2801882 thora sa up kiya now remove bcz nasir zaman ka thora opr aa rha hai baqi thk hn
      // marginTop:-2
  }
  });


  const InvoiceTitle = ({item}) => (
    <View style={styles.titleContainer}>
      <View
      style={{
        position:"absolute",
       marginTop:-45,
// backgroundColor:"grey"
      // 22-aug-2023 (2.2) 0201898 es invoice pe ye changes ki thi but update ni kiya
        // marginTop:-40
      }}
      >
          <Text style={styles.reportTitle}>{item.BranchTitle}</Text>
        <Text style={styles.addressTitle}>{item.BranchAddress}</Text>
        <Text style={styles.addressTitle}>{"Phone : "+item.BranchPhone}</Text>
      
       
      
     </View>
     <Text style={styles.ServiceTitle}>{item.BranchTiming}</Text>
  <Text style={{
      // 23-jan 
    color: 'white',
    // color: 'black',
    marginTop:item.Ref_By=="Dr. Akbar Niazi Teaching Hospital (ANTH)"?7:0,

      fontSize: 9,
      fontFamily: 'Helvetica',
      textAlign: 'left',

  }}>{item.Panel_Name.includes("Air MPL") ? "COVID-19 PCR FOR KSA": item.Panel_Name}</Text>
    </View>
  );
  
  export default InvoiceTitle