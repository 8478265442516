import React, {Fragment} from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';
import    InvoiceTableHeader from './InvoiceTableHeader'
import InvoiceTitle from './InvoiceTitle'


const InvoiceTableRow =  ({items, invoice}) =>  {
    var counter=0
// in Table header aik aur js bnae ge us me condition lga den ge agr ye group aaya to wo table header aae ga aur yhan us hisab se condition lga deni hain
    var testName ="USG Guided Trucut Biopsy\n\n - Ultrasound Marking for USG Guided Proce- dures\n - USG Guided Trucut Biopsy Procedure\n - Biopsy (Small)\n - Prothrombin Time (PT)/INR\n - Activated Partial Thromboplastin Time (APTT)\n - BARD Monopty Disposable Core Biopsy In- strument"
    var TestGroupReportingTimeDate="Same day.\n USG guid- ed procedures\n On May 14, 2024 at 10:00 PM\n On May 7, 2024 at 10:00 AM\n On May 7, 2024 at 10:00 AM\n USG guided procedures,"
    const rows =items.map( (item,index) => 
        
        {
        counter++
        

        return (
            <View>
                {/*counter%9 tha 16-jan-23 ko change kiya 2701417 report me issue tha recp unit msg line 30,40 */}
        {/* 19-jan commit */}
      
        {/* { counter%9==0 && invoice.invoice_id==""
        ?
             <View style={ {
                flexDirection: 'row',
                alignItems: 'center',
                width:"100%",
                height: item.reporting_date.includes("2022") ? "140em" : "80em"
                }} key={item.test_title} >
                    </View>
                    :null
        } */}
    
        {/* {
            counter%9==0 ? 
            // counter%9==0 ?
            <>
            <InvoiceTableHeader  />
            </>
            : null
        } */}
        <View style={ {
        flexDirection: 'row',
        borderBottomColor: 'BLACK',
        borderBottomWidth: 0.5,
        borderTopWidth: counter%9 ? 0.5 :0,
        alignItems: 'center',
        width:"100%",
        fontSize:7,
        fontStyle: 'bold'
       
        }} key={item.test_title} >
             <Text style={{ 

        width: '5%',
        textAlign: 'left',
        borderLeftColor: 'black',
        borderLeftWidth: 0.5,
        height:'100%',
        padding:5,  
        fontSize:7,
        }}>{(index+1)}</Text>
         {/* <Text style={{ 
                 
        width: '37%',
        fontFamily: 'Helvetica-Bold',
        textAlign: 'left',
        borderLeftColor: 'black',
        borderLeftWidth: 0.5,
        fontSize:6,
        height:'100%',
        padding:5
        
        
        }}>{item.test_title}</Text> */}
                    <View style={{
        // 5-may
        // width: '37%',
width: '41%',
// fontFamily: 'Helvetica-Bold',
// textAlign: 'left',
borderLeftColor: 'black',
borderLeftWidth: 0.5,
// fontSize: 6,
height: '100%',
paddingTop: 5,
paddingBottom: 5,
paddingLeft:2



}}>
                <Text style={{
                    fontFamily: 'Helvetica-Bold',
                    textAlign: 'left',   
                    fontSize: 6,
                }}>
                    {/* 5-may */}
                    {item.test_title}
                    {/* {testName} */}
                    </Text>
                  <Text style={{
textAlign: 'left',
fontSize: 6,
}}>{item.tests_line_details}</Text>
</View>
{/* //  25-apr-2024 to show reporting time in invoice for call a group in lab-test aalso agg group:true */}

{item.group ?
         <Text style={{ 
        // 5-may

        // width: '20%',
                 
        width: '22%',
        textAlign: 'left',
        borderLeftColor: 'black',
        borderLeftWidth: 0.5,
        // 5-may
        // fontSize:6,
        
        fontSize:6,
        // 5-may
        // padding: 5,
        paddingTop: 5,
        paddingLeft: 3,
        height:'100%',
        // marginTop:20
        
        
        }}>
           {'\n'}
           {'\n'}
           {/* 5-may */}
            { item.reporting_date}
          
            {/* {TestGroupReportingTimeDate} */}
        </Text>:
        <Text style={{ 
                 
            width: '19%',
            textAlign: 'left',
            borderLeftColor: 'black',
            borderLeftWidth: 0.5,
            
            fontSize:6,
            padding: 5,
            height:'100%',
            // marginTop:20
            
            
            }}>
              
                {
    
            item.reporting_date}</Text>
    }
         <Text style={{ 
                 
        width: '11%',
        textAlign: 'center',
        borderLeftColor: 'black',
        borderLeftWidth: 0.5,
        
        fontSize:6.5,
        height:'100%',
        paddingTop: 5,
        
        }}>{item.fee+" Rs"}</Text>
         <Text style={{ 
                 
        width: '11%',
        textAlign: 'center',
        borderLeftColor: 'black',
        borderLeftWidth: 0.5,
        height:'100%',
        
        fontSize:6.5,
        paddingTop: 5,
        
        
        }}>
            {/* {item.disc+" Rs"} */}
            0.00 Rs
            </Text>
            <Text style={{ 
                 
        width: '10%',
        height:'100%',
        textAlign: "center",
        borderLeftColor: 'black',
        borderLeftWidth: 0.5,
        fontSize:6.5,
        borderRightWidth: 0.5,
        // textOverflow: 'auto',
        paddingTop: 5
        
        }}>
            {/* {item.net_value+" Rs"} */}
            {item.fee+" Rs"}
            </Text>
     
     </View>
     </View>
    )
        })
    

        return (<View>{rows}</View>)
    
};
  
  export default InvoiceTableRow