
import Invoice from './Invoice/invoice'
import AppsIcon from '@material-ui/icons/Apps';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import PageviewOutlinedIcon from '@material-ui/icons/PageviewOutlined';
import PrintIcon from '@material-ui/icons/Print';


import AssessmentIcon from '@material-ui/icons/Assessment';
// Icons
import MenuIcon from '@material-ui/icons/Menu';

import DvrIcon from '@material-ui/icons/Dvr';

import ReceiptIcon from '@material-ui/icons/Receipt';

import AddPatient from './AddPatient/AddPatient.js'
import PersonAddIcon from '@material-ui/icons/PersonAdd';

import Lab_Tests from './Lab_Reports/Lab_Tests'
import ROBO from './ROBO/robo'
import ColorizeIcon from '@material-ui/icons/Colorize';
// import PostAddTwoToneIcon from '@mui/icons-material/PostAddTwoTone';
// import PostAddIcon from '@material-ui/icons/PostAddTwoTone'
import LibraryAddOutlinedIcon from '@material-ui/icons/LibraryAddOutlined';
import Summary from './PDFExport/Summary'

import Reports from './Gerry_UTF_Service/Reports'

import ShiftClosing from './ShiftClosing/ShiftClosing';

import AccessTimeIcon from '@material-ui/icons/AccessTime';

import PhelebotomyCallUp from './Phelebotomy/PhelebotomyCallUp'

import AccessioningCallsUp from './Accessioning/AccessioningCallsUp'

import FeaturedPlayListIcon from '@material-ui/icons/FeaturedPlayList';
import FeaturedPlayListOutlinedIcon from '@material-ui/icons/FeaturedPlayListOutlined';
import HomeIcon from '@material-ui/icons/Home';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import WorkList from './WorkList/WorkList'

import EditPatient from './EditPatient/CallUpPatient'

import Posting from './Posting/index'

import Payment from '@material-ui/icons/Payment';
import PostAddIcon from '@material-ui/icons/PostAdd';


// 22 Apr 2022
import SummaryInvoiceWise from './SummaryByInvoice/Summary.js'

// 23 Apr 2022
import SummaryBalance from './SummaryBalance/Summary.js'

// 27 Apr 2022
import ResultFeeding from './ResultFeeding/index'

//28 jan 2023
import PaymentReceipt from './PendingInvoice/index'
//9 feb 2023
import PaymentReceiptRecord from './PendingInvoiceRecord/index'
//18 apr 2023
import DocumentView from "./DocumentViewPanel/index"
//22 july 2024
import DocumentUpload from "./DocumentUpload/Lab_Tests.js"
//26 may 2023 
import Quotation from "./Quotation/Lab_Tests"
//06 oct 2023
import QuotationWithParameters from "./QuotationWithTestParam/Lab_Tests"
// 2-july-2024
import Lab_TestsNew from './Lab_ReportsNew/Lab_Tests'
// 30-july-2024
import Printing from "./PrintingWithLogo/Printing.js"
// 1st-sep-2024
import LabReportsDublicate from "./Lab_Reports_Dublicate/Lab_Tests.js"






var summary= localStorage.getItem("franchise")=="true" ? [
  {
    path: '/Summary',
    name: 'Summary Sample Wise',
    type: 'link',
    icon: AssessmentIcon,
    component: Summary
  },
  {
    path: '/InvoiceWise',
    name: 'Summary Invoice Wise',
    type: 'link',
    icon: AssessmentIcon,
    component: SummaryInvoiceWise
  },
  {
    path: '/BalanceDueSummary',
    name: 'Summary Balance Due',
    type: 'link',
    icon: AssessmentIcon,
    component: SummaryBalance
  }
]
:[]


var posting= localStorage.getItem("balance")=="true" ? [
  {
    path: '/PP',
    name: 'Payment Posting',
    type: 'submenu',
    icon: MenuIcon,
    // badge: {
    //   type: 'primary',
    //   value: '6',
    //   color:"red",
    
    // },
    children: [
     
    
  {
    path: '/Posting',
    name: 'Balance Due Posting',
    type: 'link',
    icon: Payment,
    component: Posting
  },
     
      {
          path: '/PaymentReceipt',
          name: 'Payment Receipt (Mutiple Invoices)',
          type: 'link',
          icon: FeaturedPlayListIcon,
          component: PaymentReceipt,
          
        },
             {
          path: '/InvoicePending',
          name: 'Payment Receipt Record',
          type: 'link',
          icon: FeaturedPlayListIcon,
          component: PaymentReceiptRecord
        },
          {
          path: '/ShiftClosing',
          name: 'Shift Closing',
          type: 'link',
          icon: AccessTimeIcon,
          component: ShiftClosing
        },
     
     
    ]
  },



  // {
  //   path: '/Posting',
  //   name: 'Posting',
  //   type: 'link',
  //   icon: Payment,
  //   component: Posting
  // },
  //  {
  //         path: '/PaymentReceipt',
  //         name: 'Payment Receipt (Mutiple Invoices)',
  //         type: 'link',
  //         icon: FeaturedPlayListIcon,
  //         component: PaymentReceipt,
          
  //       },
  //       {
  //         path: '/InvoicePending',
  //         name: 'Payment Receipt Record',
  //         type: 'link',
  //         icon: FeaturedPlayListIcon,
  //         component: PaymentReceiptRecord
  //       },
  //       // 25=july-2024 shift closing niche tha phly 
  //       {
  //         path: '/ShiftClosing',
  //         name: 'Shift Closing',
  //         type: 'link',
  //         icon: AccessTimeIcon,
  //         component: ShiftClosing
  //       },
]
:[
  // 25-july-2024 ye phly empty tha 
  {
    path: '/PP',
    name: 'Payment Posting',
    type: 'submenu',
    icon: MenuIcon,
    // badge: {
    //   type: 'primary',
    //   value: '6',
    //   color:"red",
    
    // },
    children: [
     
  
     
     
          {
          path: '/ShiftClosing',
          name: 'Shift Closing',
          type: 'link',
          icon: AccessTimeIcon,
          component: ShiftClosing
        },
     
     
    ]
  },
  
]

var routing = localStorage.getItem('user_id') ?
  localStorage.getItem("org_branch_id") == "0"  ?
      [
        {
          path: '/',
          name: 'Home Page',
          type: 'link',
          icon: HomeOutlinedIcon ,
          component: Invoice
        },
        {
          path: '/RB',
          name: 'Registration & Billing',
          type: 'submenu',
          icon: MenuIcon,
          // badge: {
          //   type: 'primary',
          //   value: '6',
          //   color:"red",
          
          // },
          // 1-sep-2024 sir najam ko dublicate invoice wala module show krwana hai us k lye ye condition lga k show krw rha
          children:localStorage.getItem("name") == "najam" &&localStorage.getItem("user_id")=="UR-001" ? [
           
            {
              path: '/AddPatient',
              name: 'Create Patient MR',
    
              // name: 'Add Patient',
              type: 'link',
              icon: PersonAddIcon,
              component: AddPatient
            },
            {
              path: '/EditPatient',
              name: 'Edit Patient MR',
              type: 'link',
              icon: ColorizeIcon,
              component: EditPatient
            },
            {
              path: '/CreateInvoice',
              name: 'Create Invoice',
              type: 'link',
              icon: ReceiptIcon,
              component: Invoice
            },
            {
              path: '/Invoices',
              name: 'Invoice Record',
              type: 'link',
              icon: ReceiptIcon,
              component: Lab_Tests
            },
            {
              path: '/LabReportsDublicate',
              name: 'Duplicate Invoice Record',
              type: 'link',
              icon: ReceiptIcon,
              component: LabReportsDublicate
            },
            {
              path: '/InvoicesTestwise',
              name: 'Invoice Record TestWise',
              type: 'link',
              icon: ReceiptIcon,
              component: Lab_TestsNew
            },
            
            {
              path: '/DocumentUpload',
              name: 'Document Upload',
              type: 'link',
              icon: LibraryAddOutlinedIcon,
              component: DocumentUpload
            },
          ]:
          [
           
            {
              path: '/AddPatient',
              name: 'Create Patient MR',
    
              // name: 'Add Patient',
              type: 'link',
              icon: PersonAddIcon,
              component: AddPatient
            },
            {
              path: '/EditPatient',
              name: 'Edit Patient MR',
              type: 'link',
              icon: ColorizeIcon,
              component: EditPatient
            },
            {
              path: '/CreateInvoice',
              name: 'Create Invoice',
              type: 'link',
              icon: ReceiptIcon,
              component: Invoice
            },
            {
              path: '/Invoices',
              name: 'Invoice Record',
              type: 'link',
              icon: ReceiptIcon,
              component: Lab_Tests
            },
            {
              path: '/InvoicesTestwise',
              name: 'Invoice Record TestWise',
              type: 'link',
              icon: ReceiptIcon,
              component: Lab_TestsNew
            },
            {
              path: '/DocumentUpload',
              name: 'Document Upload',
              type: 'link',
              icon: LibraryAddOutlinedIcon,
              component: DocumentUpload
            },
          ]
        },
        {
          path: '/PA',
          name: 'Phelobotomy & Accessioning',
          type: 'submenu',
          icon: MenuIcon,
          // badge: {
          //   type: 'primary',
          //   value: '6',
          //   color:"red",
          
          // },
         
          children: [
           
            {
              path: '/ROBO',
              name: 'BC ROBO 7',
              type: 'link',
              icon: DvrIcon,
              component: ROBO
            },
           
            {
              path: '/Phelebotomy',
              name: 'Do Phelebotomy',
              type: 'link',
              icon: ColorizeIcon,
              component: PhelebotomyCallUp
            },
            {
              path: '/Accessioning',
              name: 'Do Accessioning',
              type: 'link',
              icon: FeaturedPlayListOutlinedIcon,
              component: AccessioningCallsUp
            },
            {
              path: '/DocumentView',
              name: 'Document View',
              type: 'link',
              icon: PageviewOutlinedIcon,
              component: DocumentView
            },
           
           
          ]
        },
        // {
        //   path: '/PA',
        //   name: 'Phelobotomy & Accessioning',
        //   type: 'submenu',
        //   icon: MenuIcon,
        //   // badge: {
        //   //   type: 'primary',
        //   //   value: '6',
        //   //   color:"red",
          
        //   // },
         
        //   children: [
           
        //     {
        //       path: '/ROBO',
        //       name: 'BC ROBO 7',
        //       type: 'link',
        //       icon: DvrIcon,
        //       component: ROBO
        //     },
           
        //     {
        //       path: '/Phelebotomy',
        //       name: 'Do Phelebotomy',
        //       type: 'link',
        //       icon: ColorizeIcon,
        //       component: PhelebotomyCallUp
        //     },
        //     {
        //       path: '/Accessioning',
        //       name: 'Do Accessioning',
        //       type: 'link',
        //       icon: FeaturedPlayListOutlinedIcon,
        //       component: AccessioningCallsUp
        //     },
        //     {
        //       path: '/DocumentView',
        //       name: 'Document View',
        //       type: 'link',
        //       icon: PageviewOutlinedIcon,
        //       component: DocumentView
        //     },
           
           
        //   ]
        // },
        
        // {
        //   path: '/',
        //   name: 'Invoice',
        //   type: 'link',
        //   icon: ReceiptIcon,
        //   component: Invoice
        // },
        // {
        //   path: '/AddPatient',
        //   name: 'Add Patient',
        //   type: 'link',
        //   icon: PersonAddIcon,
        //   component: AddPatient
        // },
        // {
        //   path: '/Invoices',
        //   name: 'Invoice Record',
        //   type: 'link',
        //   icon: ReceiptIcon,
        //   component: Lab_Tests
        // }
        // ,
        // {
        //   path: '/InvoicesTestwise',
        //   name: 'Invoice Record TestWise',
        //   type: 'link',
        //   icon: ReceiptIcon,
        //   component: Lab_TestsNew
        // },
    
        // {
        //   path: '/ROBO',
        //   name: 'BC ROBO 7',
        //   type: 'link',
        //   icon: DvrIcon,
        //   component: ROBO
        // }
        // ,
        // {
        //   path: '/Phelebotomy',
        //   name: 'Phelebotomy',
        //   type: 'link',
        //   icon: ColorizeIcon,
        //   component: PhelebotomyCallUp
        // }
        // ,
        // {
        //   path: '/Accessioning',
        //   name: 'Accessioning',
        //   type: 'link',
        //   icon: FeaturedPlayListOutlinedIcon,
        //   component: AccessioningCallsUp
        // },
     
        // {
        //   path: '/DocumentView',
        //   name: 'Document View',
        //   type: 'link',
        //   icon: PageviewOutlinedIcon,
        //   component: DocumentView
        // },
        // {
        //   path: '/DocumentUpload',
        //   name: 'Document Upload',
        //   type: 'link',
        //   icon: LibraryAddOutlinedIcon,
        //   component: DocumentUpload
        // },
        
        // {
        //   path: '/ShiftClosing',
        //   name: 'Shift Closing',
        //   type: 'link',
        //   icon: AccessTimeIcon,
        //   component: ShiftClosing
        // },
        // {
        //   path: '/Quotation',
        //   name: 'Quotation',
        //   type: 'link',
        //   icon: PostAddIcon,
        //   component: Quotation
        // }
        // ,
        // {
        //   path: '/QuotationWithParameters',
        //   name: 'Quotation With Test Parameters',
        //   type: 'link',
        //   icon: PostAddIcon,
        //   component: QuotationWithParameters
        // }
        // ,
        // {
        //   path: '/EditPatient',
        //   name: 'EditPatient',
        //   type: 'link',
        //   icon: ColorizeIcon,
        //   component: EditPatient
        // },
        
        ...posting,
        {
          path: '/QS',
          name: 'Quotations',
          type: 'submenu',
          icon: MenuIcon,
          // badge: {
          //   type: 'primary',
          //   value: '6',
          //   color:"red",
          
          // },
         
          children: [
           
            {
              path: '/Quotation',
              name: 'Quotation',
              type: 'link',
              icon: PostAddIcon,
              component: Quotation
            },
           
            ,
            {
              path: '/QuotationWithParameters',
              name: 'Quotation With Test Parameters',
              type: 'link',
              icon: PostAddIcon,
              component: QuotationWithParameters
            }
           
           
           
          ]
        },
       
      ] :
      [
   
        {
          path: '/',
          name: 'Invoice',
          type: 'link',
          icon: ReceiptIcon,
          component: Invoice
        },
        {
          path: '/AddPatient',
          name: 'Add Patient',
          type: 'link',
          icon: PersonAddIcon,
          component: AddPatient
        },
        {
          path: '/Invoices',
          name: 'Invoice Record',
          type: 'link',
          icon: ReceiptIcon,
          component: Lab_Tests
        }
        ,
        {
          path: '/InvoicesTestwise',
          name: 'Invoice Record TestWise',
          type: 'link',
          icon: ReceiptIcon,
          component: Lab_TestsNew
        },
        // 10-july-2024 ccp portal merge into invoicing portal ye phelbotomy he hai hai just name change kiya barcode printing kiya
        {
          path: '/Phelebotomy',
          name: 'Barcode Printing',
          type: 'link',
          icon: PrintIcon,
          component: PhelebotomyCallUp
        },
        {
          path: '/Quotation',
          name: 'Quotation',
          type: 'link',
          icon: PostAddIcon,
          component: Quotation
        }
        
        ,
        {
          path: '/WorkList',
          name: 'Work List',
          type: 'link',
          icon: AssessmentIcon,
          component: WorkList
        },
        // {
        //   path: '/ShiftClosing',
        //   name: 'Shift Closing',
        //   type: 'link',
        //   icon: AccessTimeIcon,
        //   component: ShiftClosing
        // },
      
        ...summary
        ,
        ...posting,
        {
          path: '/Printing',
          name: 'Printing',
          type: 'link',
          icon: AccessTimeIcon,
          component: Printing,
         
        },

      ]
  // {
  //   path: '/Radiology',
  //   name: 'Result Feed & Sign',
  //   type: 'link',
  //   icon: EditIcon,
  //   component: Radiology
  // }


  : []

export default {
  items: [
    ...routing,
    
    // {
    //   path: '/apps',
    //   name: 'Apps',
    //   type: 'submenu',
    //   icon: AppsIcon,
    //   badge: {
    //     type: 'primary',
    //     value: '5'
    //   },
    //   children: [
    //     {
    //       path: '/calendar',
    //       name: 'Calendar',
    //       component: Calendar
    //     },
    //     {
    //       path: '/media',
    //       name: 'Media',
    //       component: Media
    //     },
    //     {
    //       path: '/messages',
    //       name: 'Messages',
    //       component: Messages
    //     },
    //     {
    //       path: '/social',
    //       name: 'Social',
    //       component: Social
    //     },
    //     {
    //       path: '/chat',
    //       name: 'Chat',
    //       component: Chat
    //     }
    //   ]
    // },
    // {
    //   path: '/widgets',
    //   name: 'Widgets',
    //   type: 'link',
    //   icon: PhotoIcon,
    //   component: Widgets
    // },
    // {
    //   path: '/material',
    //   name: 'Material',
    //   type: 'submenu',
    //   icon: EqualizerIcon,
    //   badge: {
    //     type: 'error',
    //     value: '10'
    //   },
    //   children: [
    //     {
    //       path: '/appbar',
    //       name: 'App Bar',
    //       component: AppBar
    //     },
    //     {
    //       path: '/autocomplete',
    //       name: 'Auto Complete',
    //       component: Autocomplete
    //     },
    //     {
    //       path: '/avatars',
    //       name: 'Avatars',
    //       component: Avatars
    //     },
    //     {
    //       path: '/badges',
    //       name: 'Badges',
    //       component: Badges
    //     },
    //     {
    //       path: '/button-navigation',
    //       name: 'Button Navigation',
    //       component: ButtonNavigation
    //     },
    //     {
    //       path: '/buttons',
    //       name: 'Buttons',
    //       component: Buttons
    //     },
    //     {
    //       path: '/cards',
    //       name: 'Cards',
    //       component: Cards
    //     },
    //     {
    //       path: '/chips',
    //       name: 'Chips',
    //       component: Chips
    //     },
    //     {
    //       path: '/dialogs',
    //       name: 'Dialogs',
    //       component: Dialogs
    //     },
    //     {
    //       path: '/dividers',
    //       name: 'Dividers',
    //       component: Dividers
    //     },
    //     {
    //       path: '/drawers',
    //       name: 'Drawers',
    //       component: Drawers
    //     },
    //     {
    //       path: '/expansion-panels',
    //       name: 'Expansion Panels',
    //       component: ExpansionPanels
    //     },
    //     {
    //       path: '/grid-list',
    //       name: 'Grid List',
    //       component: GridList
    //     },
    //     {
    //       path: '/lists',
    //       name: 'Lists',
    //       component: Lists
    //     },
    //     {
    //       path: '/menus',
    //       name: 'Menus',
    //       component: Menus
    //     },
    //     {
    //       path: '/paper',
    //       name: 'Paper',
    //       component: Paper
    //     },
    //     {
    //       path: '/pickers',
    //       name: 'Pickers',
    //       component: Pickers
    //     },
    //     {
    //       path: '/progress',
    //       name: 'Progress',
    //       component: Progress
    //     },
    //     {
    //       path: '/selection-controls',
    //       name: 'Selection Controls',
    //       component: SelectionControls
    //     },
    //     {
    //       path: '/selects',
    //       name: 'Selects',
    //       component: Selects
    //     },
    //     {
    //       path: '/snackbars',
    //       name: 'Snackbars',
    //       component: Snackbars
    //     },
    //     {
    //       path: '/steppers',
    //       name: 'Steppers',
    //       component: Steppers
    //     },
    //     {
    //       path: '/tables',
    //       name: 'Tables',
    //       component: Tables
    //     },
    //     {
    //       path: '/tabs',
    //       name: 'Tabs',
    //       component: Tabs
    //     },
    //     {
    //       path: '/text-fields',
    //       name: 'Text Fields',
    //       component: TextFields
    //     },
    //     {
    //       path: '/tooltips',
    //       name: 'Tooltips',
    //       component: Tooltips
    //     }
    //   ]
    // },
    // {
    //   path: '/editor',
    //   name: 'Editor',
    //   type: 'link',
    //   icon: Looks3Icon,
    //   component: Editor
    // },
    // {
    //   path: '/ecommerce',
    //   name: 'Ecommerce',
    //   type: 'submenu',
    //   icon: Looks4Icon,
    //   badge: {
    //     type: 'secondary',
    //     value: 'N'
    //   },
    //   children: [
    //     {
    //       path: '/products',
    //       name: 'Products',
    //       component: Products
    //     },
    //     {
    //       path: '/detail',
    //       name: 'Detail',
    //       component: Detail
    //     }
    //   ]
    // },
    // {
    //   path: '/taskboard',
    //   name: 'Taskboard',
    //   type: 'link',
    //   icon: ViewColumnIcon,
    //   component: Taskboard
    // },
    // {
    //   path: '/charts',
    //   name: 'Charts',
    //   type: 'link',
    //   icon: ShowChartIcon,
    //   component: Charts
    // },
    // {
    //   path: '/maps',
    //   name: 'Maps',
    //   type: 'submenu',
    //   icon: NavigationIcon,
    //   children: [
    //     {
    //       path: '/google',
    //       name: 'Google',
    //       component: Google
    //     },
    //     {
    //       path: '/leaflet',
    //       name: 'Leaflet',
    //       component: Leaflet
    //     }
    //   ]
    // },
    // {
    //   path: '/pages',
    //   name: 'Pages',
    //   type: 'submenu',
    //   icon: PagesIcon,
    //   children: [
    //     {
    //       path: '/invoice',
    //       name: 'Invoice',
    //       component: Invoice
    //     },
    //     {
    //       path: '/timeline',
    //       name: 'Timeline',
    //       component: TimelinePage
    //     },
    //     {
    //       path: '/blank',
    //       name: 'Blank',
    //       component: Blank
    //     },
    //     {
    //       path: '/pricing',
    //       name: 'Pricing',
    //       component: PricingPage
    //     }
    //   ]
    // },
    // {
    //   name: 'Authentication',
    //   type: 'submenu',
    //   icon: PersonIcon,
    //   children: [
    //     {
    //       path: '/signin',
    //       name: 'Signin',
    //       component: Signin
    //     },
    //     {
    //       path: '/signup',
    //       name: 'Signup',
    //       component: Signup
    //     },
    //     {
    //       path: '/forgot',
    //       name: 'Forgot',
    //       component: PasswordReset
    //     },
    //     {
    //       path: '/lockscreen',
    //       name: 'Lockscreen',
    //       component: Lockscreen
    //     }
    //   ]
    // },
    // {
    //   name: 'Error',
    //   type: 'submenu',
    //   icon: FaceIcon,
    //   children: [
    //     {
    //       path: '/404',
    //       name: '404',
    //       component: NotFound
    //     },
    //     {
    //       path: '/500',
    //       name: 'Error',
    //       component: BackendError
    //     }
    //   ]
    // },
    // {
    //   path: 'https://iamnyasha.github.io/react-primer-docs/',
    //   name: 'Documentation',
    //   type: 'external',
    //   icon: LocalLibraryIcon
    // },
    // {
    //   path: 'https://primer.fusepx.com',
    //   name: 'Get Angular Version',
    //   type: 'external',
    //   icon: BookmarkIcon
    // }
  ]
};
