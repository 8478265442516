import React, { useEffect, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import HelpIcon from '@material-ui/icons/Help';
import { isMobile } from 'react-device-detect';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import GetAppIcon from '@material-ui/icons/GetApp';
import ReceiptIcon from '@material-ui/icons/Receipt';
import EditIcon from '@material-ui/icons/Edit';
import Grid from "@material-ui/core/Grid";

import PictureAsPdfSharpIcon from '@material-ui/icons/PictureAsPdfSharp';

import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

import { DatePicker, KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import DateFnsUtils from '@date-io/date-fns';
import Datetime from 'react-datetime'
import ReactLoading from 'react-loading';





// 


import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import DialogActions from "@material-ui/core/DialogActions";

import Slide from "@material-ui/core/Slide";

import { PDFViewer } from '@react-pdf/renderer'

import { Page, Text, View, Document, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';

import SearchIcon from '@material-ui/icons/Search';

import Select from '@material-ui/core/Select';

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";


import RefreshIcon from '@material-ui/icons/Refresh';

import GridContainer from "../components/Grid/GridContainer.js";
import GridItem from "../components/Grid/GridItem.js";

import TextField from '@material-ui/core/TextField';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { GridList } from '@material-ui/core';


//20-june-2024 for acs desc
import TableSortLabel from '@material-ui/core/TableSortLabel';




const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}
const columnsforCCP =localStorage.getItem("org_branch_id")==1?
[

// { id: 'download', label: 'Report' },


]:[
  { id: 'simple_invoice', label: 'Simple Invoice' },

  { id: 'sample_invoice', label: 'Service Order' },

  { id: 'sample_invoiceNew', label: 'Outsource Service Order' },
  // 28-feb-23
  { id: 'prescription', label: 'Prescription' },
 //  16-apr-2024
 { id: 'prescriptionSimple', label: 'Prescription Simple' },

  //20-june-2024 hide

 // { id: 'barcode', label: <p style={{
 //   textAlign:'center'
 // }}>Barcode (Branches)</p> },
 { id: 'receipt', label: 'Receipt' },
]
const columns =
  [
  { id: 'sn', label: 'SN' },

  { id: 'invoice_no', label:localStorage.getItem("branch_name")?.includes("MPL")?"MPL ID":localStorage.getItem("branch_name")?.includes("FSLS")?"FSLS ID": 'Invoice ID' },
  { id: 'patient_id', label: 'MR No' },
  { id: 'patient_name', label: 'Patient Name' },
  { id: 'mobile_no', label: 'Mobile No' },

  { id: 'branch_name', label: 'Branch' },
  { id: 'uploadDocument', label: 'Upload Documents' },



  // { id: 'bookingDateTime', label: 'Booking D/T' },
  // { id: 'tests_included', label: 'Tests' },
 

  // { id: 'remarks', label: 'Remarks' },
  //for Edit Patient 11-jan-2023
  
  // { id: 'patient_invoice', label: 'Printed Invoice' },
  // { id: 'lab_order', label: 'Lab Order' },
  // 1st-april-2023 for Outsource service order
  
   //09-march-2023 //20-june-2024 hide
  //  {id:'barcodeSticker' , label:'Print Report Sticker'},
// ...columnsforCCP
  ]

function addZero(i) {
  if (i < 10) {
    i = "0" + i;
  }
  return i;
}
function to24Hours(d) {
  var h = addZero(d.getHours());
  var m = addZero(d.getMinutes());
  var s = addZero(d.getSeconds());
  return h + ":" + m + ":" + s;
}
const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 1000,
  },
});
function msToTime(seconds) {

  var minutes = parseInt(seconds / 60, 10);
  seconds = seconds % 60;
  var hours = parseInt(minutes / 60, 10);
  minutes = minutes % 60;

  return addZero(hours) + ':' + addZero(minutes);
}

export default function Lab_Tests() {
  const classes = useStyles();
  var date = new Date()
  var temp_role = localStorage.getItem('role')
  date.setDate(date.getDate() );

  const [selected, setSelected] = React.useState({
    reports: []
  })

  const [OpenCovidTemplate, setCovidTemplate] = React.useState(false)

  const [OpenOfficialCovidTemplate, setOfficialCovidTemplate] = React.useState(false)

  const [patient_invoice, setPatientInvoice] = React.useState(false)


  const [simple_invoice, setSimpleInvoice] = React.useState(false)

  const [sample_invoice, setSampleInvoice] = React.useState(false)
  // 1st-april-2023 for Outsource service order
  const [sample_invoiceNew, setSampleInvoiceNew] = React.useState(false)


//28-feb-23
const [prescriptionCond, setprescriptionCond] = React.useState(false)
// 16-apr-2024
const [prescriptionCondFemale, setprescriptionCondFemale] = React.useState(false)
const [prescriptionCondSimple, setprescriptionCondSimple] = React.useState(false)

const [prescriptionCondFemaleSimple, setprescriptionCondFemaleSimple] = React.useState(false)
// 8-amy-2024
const [selectedPrescription, setSelectedPrescription] = React.useState({
  reports: []
})


  const [from, setFrom] = React.useState(date)
  const [to, setTo] = React.useState(new Date())
  const [width, height] = [window.innerWidth, window.outerWidth];
  const [panel, setPanel] = React.useState('All')
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([]);
  // new State for document Upload
  const [mr_no, setmr_no] = React.useState("")
  const [invoice_id, setinvoice_id] = React.useState("")
  const [patient_name, setpatient_name] = React.useState("")
  const [mobile_no, setmobile_no] = React.useState("")

  const [dms_title_pdf , setdms_title_pdf ] = React.useState("")
  const [dms_title_img, setdms_title_img] = React.useState("")
  const [String_PDF, setString_PDF] = React.useState("")
  const [String_IMG, setString_IMG] = React.useState("")
  const [DocumentUploadModal, setDocumentUploadModal] = React.useState("")
  // state for pdf and image from invoice
  const [ScanImage, setScanImage] = React.useState('')
  const [ScanPDF, setScanPDF] = React.useState('')
  const [pdfScanFlag,setpdfScanFlag]=React.useState(false)
  // functions
  const handleScanImage = async (event) => {
    console.log("Invoice HandlerScanImage 417 :",event)
    const file = event.target.files[0]
    var fileInput = document.getElementById('ScanImage');
    var filePath = fileInput.value;
    var allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
    if (!allowedExtensions.exec(filePath)) {
      alert('Please upload file having extensions .jpeg/.jpg/.png only.');
      fileInput.value = '';
      setScanImage('')
      return false;
    } else {
      console.log("Invoice HandlerScanImage 428 before convert into base :",file)
      const base64 = await convertBase64(file)
      console.log("Invoice HandlerScanImage 430 after convert into base :",base64)
      setScanImage(base64)
    }

  }
  const handleScanPDF = async (event) => {
    const file = event.target.files[0]
    console.log(file)
    var fileInput = document.getElementById('ScanPDF');
    var filePath = fileInput.value;
    var allowedExtensions = /(\.pdf)$/i;

    if (!allowedExtensions.exec(filePath)) {
      alert('Please upload file having extensions .pdf only.');
      fileInput.value = '';
      setScanPDF('')
      return false;
    } else {
      const base64 = await convertBase64(file)
      console.log("after convert into base64"+base64)
      // console.log("after convert into base64"+base64.split('base64,')[1])

      setScanPDF(base64)
    }

  }
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }


  //for Edit Patient 11-jan-2023
  


  const [selectedCovid, setSelectedCovid] = React.useState({
    reports: []
  })
  const [selectedOfficialCovidReport, setSelectedOfficialCovidReport] = React.useState({
    reports: []
  })


  const [org_rows, setOrg_Rows] = React.useState([]);
  const [loading, setLoading] = React.useState(true)

  const [covidButton, setCovidButton] = React.useState(false)
// 26-june-2024
  const [panelList, setpanelList] = React.useState([])
  const [selectedPanel, setselectedPanel] = React.useState("")

  const [laborder, setLabOrder] = React.useState(false)
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const [branches, setBranches] = React.useState([])
  const [branch, setBranch] = React.useState("")
  const [selectBranch, setSelectBranch] = React.useState([])
  const [selectOrders, setSelectedOrders] = React.useState([])
  const [state, setState] = React.useState({
    status: 'All',
  });
  // 26-june-2024
  

  //// --------------------------------------
  const [selectedReceipt,setSelectedReceipt]=React.useState("")
  const [selectReceiptOpen,setSelectReceiptOpen]=React.useState(false)

  // 20-june-2024
  
  const [orderBy, setOrderBy] = React.useState('id');
  //es asc ko decs me kr den ge to sara kam bgair click k desc kr den ge bydefault 2nd mrnumber pe lga k check kren
  const [order, setOrder] = React.useState('desc');
   const handleSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const sortedRows = rows.sort((a, b) => {
    const orderMultiplier = order === 'asc' ? 1 : -1;
    const aValue = a[orderBy];
    const bValue = b[orderBy];
    if (aValue < bValue) {
      return -1 * orderMultiplier;
    }
    if (aValue > bValue) {
      return 1 * orderMultiplier;
    }
    return 0;
  });

  var svgString2Image = (svgString, width, height, format, callback) => {

  if(svgString.trim()==""){
    callback('')
    return ""
  }

    var svgStringFormat = window.atob(svgString)
    var temp = svgStringFormat.split(`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">`)
    // set default for format parameter
    format = format ? format : 'png';
    // SVG data URL from SVG string
    var svgData = 'data:image/svg+xml;base64,' + window.btoa(unescape(encodeURIComponent(temp[1])));
    // create canvas in memory(not in DOM)
    var canvas = document.createElement('canvas');
    // get canvas context for drawing on canvas
    var context = canvas.getContext('2d');
    // set canvas size
    canvas.width = width;
    canvas.height = height;
    // create image in memory(not in DOM)
    var image = new Image();
    // later when image loads run this
    image.onload = function () { // async (happens later)
      // clear canvas
      context.clearRect(0, 0, width, height);
      // draw image with SVG data to canvas
      context.drawImage(image, 0, 0, width, height);
      // snapshot canvas as png
      var pngData = canvas.toDataURL('image/' + format);
      // pass png data URL to callbac
      callback(pngData)
    }; // end async
    image.src = svgData

  }

  const handleChange = async(event) => {
    console.log("me call hua")
    console.log(state)
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
    var lab_tests = []
    var text = event.target.value
    if(text!="All"){
    var data = await org_rows.map((item) => {
  
      if (item.tests_included.includes(text)) {
        lab_tests.push(item)
        return

      }

      

      return item

    })
    Promise.all(data).then((item) => {

      setRows(lab_tests)

    })
  }
  else{
    setRows(org_rows)
  }
  };
  const numberWithCommas= (x)=> {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); 
  }
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (localStorage.getItem('org_branch_id') == null || localStorage.getItem('org_branch_id') == undefined) {
      localStorage.clear()
      window.location.href = "https://invoicing.mpl-labs.pk/login"
    }
    if (localStorage.getItem('user_id') == null || localStorage.getItem('user_id') == undefined) {
      localStorage.clear()
      window.location.href = "https://invoicing.mpl-labs.pk"
    }
    fetch("https://reports.mpl-labs.pk:8443/4DACTION/BranchesLoadAllfromWebserver").then(res => res.json()).then((response) => {
      setBranches(response)

    })
    var patient_id = localStorage.getItem('patient_id')
    var role = localStorage.getItem('role')
    // 10-july-2024
   
    setselectedPanel(localStorage.getItem("panel_code"))

    var from = new Date();


    var branchID = localStorage.getItem('branch_id')

    var to = new Date();
    var SearchFormData = { PIN: "", PhoneNo: "", PatientName: "", RegDateFrom: from.toISOString(), RegDateTo: to.toISOString(), vBranchID: branchID }
    
    var url = "https://reports.mpl-labs.pk:8443/4DACTION/InvoiceListCalledByPrintPortal"
   
    to.setHours(15)
    from.setHours(15)
    fetch(url,{
      method:"POST",
      body:JSON.stringify({
        branch_id:JSON.parse(branchID),
        date_start:from.toISOString(),
        date_end:to.toISOString(),
    // 10-july-2024

        panel_code:localStorage.getItem("panel_code")

      })
    }).then((res) => res.json())
      .then((result) => {
       console.log("Invoice Record 283 :",result)
        var the_rows = []
        //################ response of Api result Map (data) start ####################
        result.map((data,index) => {
          var br_title = ""

          if (data.branch_name == "METROPOLE") {
            br_title = "Islamabad"
          } else {
            br_title = data.branch_name.split('MPL,')[1]
          }
          //ye wala object niche the-rows me push krty hn aur table me show krty hn if 100 vale in result then 100 obj will be push on the rows
          var obj = {
            sn:index+1,
            ...data,
            tests_included:data.tests_included.includes('^') ? data.tests_included.replaceAll('^','(').replaceAll('|',"),")+")" : data.tests_included.replaceAll('^','(').replaceAll('|',"),") ,
            bookingDateTime:new Date(data.invoice_date).ddmmyyy()+", "+msToTime(data.invoice_time)+" hrs",
            uploadDocument:
            <center>
              {/* this is Report  */}
              
                    <Button variant="contained" color="secondary" style={{backgroundColor:"#196c91"}} disableElevation onClick={() => {
                    // 15-july-2024  if (data.Test_Status == 'Results Ready') {
                      
                    setDocumentUploadModal(true)
                        // alert('Results not Ready!!')
                        setmr_no(data.patient_id)
                        setinvoice_id(data.invoice_no)
                        setpatient_name(data.patient_name.trim())
                        setmobile_no(data.mobile_no)
                   
                    }} >
                      {/* <PictureAsPdfSharpIcon /> */}
                      <  ReceiptIcon />
                    </Button>
      
            
      
            </center>
      
 

          }
          the_rows.push(obj)

        })
        //################ response of Api result Map (data) end ####################
     
        setRows(the_rows)
        setOrg_Rows(the_rows)
        setLoading(false)
      }).catch(()=>{
        setLoading(false)
        setPage(0)
      })
    
    // fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebPortal_GetAllPanelsList").then((res) => res.json())
    //   .then((result) => {
    //     setpanelList(result)
    //   })
    var obj22={
      branch_id:localStorage.getItem("branch_id")
    }
  fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebPortal_GetPanelsListBranch",
  {
         method:"POST",
  'Content-Type':"application/json",
  body: JSON.stringify(obj22)
  }
  ).then((res) => res.json()).then((response) => {
      console.log("GetPanelsListBranch"+JSON.stringify(response))
      // if(response.cred)
    response.push({
      Panel_Code: "Normal",
      Panel_Title: "Normal Patient"
    })
    setpanelList(response)
   
  })
    if (localStorage.getItem('org_branch_id') == '0') {
      fetch("https://reports.mpl-labs.pk:8443/4DACTION/BranchesLoadAllfromWebserver").then(res => res.json()).then((response) => {
        setBranches(response)
        var obj = {}
        response.map((item) => {
          obj[item.BranchID] = item.BranchName
        })
        setSelectBranch(obj)

        setBranch(localStorage.getItem('branch_id'))
      })
    }


  }, [])
  Date.prototype.hhmm = function () {
    var mm = this.getMinutes();
    var hh = this.getHours();

    return [
      (hh > 9 ? '' : '0') + hh,
      (mm > 9 ? '' : '0') + mm
    ].join(':');
  };

  Date.prototype.ddmmyyy = function () {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();

    return [
      (dd > 9 ? '' : '0') + dd,
      (mm > 9 ? '' : '0') + mm,
      this.getFullYear()
    ].join('/');
  };
  return (
    <>
      <Paper className={classes.root}>
        {/* <div style={{ padding: '1em', textAlign: 'center' }}>
          {localStorage.getItem('org_branch_id') == '0' ? <h3 style={{ color: 'red' }}>Branch Selection will change the Booking, Invoicing and Reporting Branch</h3> : null}
        </div> */}
        <GridContainer style={{ padding: '2em'}}>
          <GridItem xs={6} sm={6} md={3} lg={3}>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                variant="inline"
                inputVariant="outlined"
                label="From"
                format="dd/MM/yyyy"
                value={from}
                InputAdornmentProps={{ position: "start" }}
                onChange={date => setFrom(date)}
              />
            </MuiPickersUtilsProvider>
          </GridItem>
          {/* {
            width < 600
              ?
              <GridItem xs={6} sm={6} md={0} lg={0}>

              </GridItem> : ""
          } */}

          <GridItem xs={6} sm={6} md={3} lg={3}>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                variant="inline"
                inputVariant="outlined"
                label="To"
                format="dd/MM/yyyy"
                value={to}
                InputAdornmentProps={{ position: "start" }}
                onChange={date => setTo(date)}
              />
            </MuiPickersUtilsProvider>

          </GridItem>
          {/* {
            width < 600
              ?
              <GridItem xs={6} sm={6} md={0} lg={0}>

              </GridItem> : ""
          } */}
          {/* <GridItem xs={6} sm={6} md={2} lg={2}>
            <FormControl variant="filled" className={classes.formControl} style={{ width: "-webkit-fill-available" }} >
              <InputLabel id="demo-simple-select-filled-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                value={state.status}
                onChange={handleChange}
                inputProps={{
                  name: 'status',
                  id: 'filled-age-native-simple',
                }}
              >
                <MenuItem aria-label="Select" value="All" >All</MenuItem>
                <MenuItem value={'Just Booked'}>Just Booked</MenuItem>
                <MenuItem value={'Only Phlebotomy Done'}>Only Phlebotomy Done</MenuItem>
                <MenuItem value={'Lying Pending Results'}>Lying Pending Results</MenuItem>
                <MenuItem value={'Results Done But not Signed'}>Results Done But not Signed</MenuItem>
                <MenuItem value={'Results Ready'}>Results Ready</MenuItem>
                <MenuItem value={'(Delivered)'}>Results Delivered</MenuItem>

              </Select>
            </FormControl>
          </GridItem> */}
         
          {
            localStorage.getItem('org_branch_id') == '0' ?
              <GridItem xs={12} sm={12} md={3} lg={3}  >
                <FormControl variant="outlined" className={classes.formControl} style={{ width: "15em" }} >
                  <InputLabel id="demo-simple-select-filled-label">Branch</InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={branch}
                    onChange={(e) => {
                      var result = window.confirm('Are you Sure ?. Your want to change Branch?')
                      if (result) {
                        setBranch(e.target.value)
                        localStorage.setItem('branch_id', e.target.value)
                        localStorage.setItem('branch_name', selectBranch[e.target.value])
                        window.location.reload()
                      }

                    }}
                  >
                    <MenuItem value={"0"} disabled>Select</MenuItem>
                    {
                      branches.map((item) => {
                        return <MenuItem value={item.BranchID} key={item.BranchID}>{item.BranchName.split('MPL, ')[1]}</MenuItem>

                      })
                    }
                  </Select>
                </FormControl>
              </GridItem>
              : null
          }
          {/* 10-julu-2024 */}

          {/* {localStorage.getItem("org_branch_id")==1?<></>:
           <GridItem xs={6} sm={6} md={2} lg={2}>

<Autocomplete
  id="country-select"
  options={panelList}
  classes={{
    option: classes.option
  }}
  autoHighlight
   onChange={(event, newValue) => {
    if(newValue!=null){
      console.log("newVlaue")
      console.log(newValue)


      // handleChange({
      //   target:{
      //     value:newValue.Panel_Code
      //   }
      // })
      setselectedPanel(newValue.Panel_Code)
      
    }else{
      // handleChange({
      //   target:{
      //     value:"Normal"
      //   }
      // })
      console.log(newValue)

      setselectedPanel("")
    }
  }}
  getOptionLabel={(option) => option.Panel_Title}
  style={{
    // width:"10em"
  }}
  renderOption={(option) => <React.Fragment>{option.Panel_Title}</React.Fragment>}
  
  renderInput={(params) => (
    <TextField
      {...params}
      label="Choose a Panel"
      variant="outlined"
      style={{
        // width:"10em"
      }}
      inputProps={{
        ...params.inputProps,
        autoComplete: "new-password" // disable autocomplete and autofill
      }}
    />
  )}
/>
</GridItem>} */}
          <GridItem xs={12} sm={12} md={3} lg={3} style={{ margin: 'auto' }} >
        
            <Button variant="contained" color="primary" style={{backgroundColor:"#196c91"}} disableElevation disabled={loading}
              onClick={() => {
                // alert(selectedPanel)
                setPage(0)
                document.getElementById('search_data').value = ""
                var role = localStorage.getItem('role')
                var patient_id = localStorage.getItem('patient_id')
                setLoading(true)

                var branchID = localStorage.getItem('branch_id')
                var SearchFormData = { PIN: "", PhoneNo: "", PatientName: "", RegDateFrom: from.toISOString(), RegDateTo: to.toISOString(), vBranchID: branchID }
                var vSearchStr = JSON.stringify(SearchFormData);

                var url = "https://reports.mpl-labs.pk:8443/4DACTION/InvoiceListCalledByPrintPortal"


                to.setHours(15)
                from.setHours(15)
                
                fetch(url,{
                  method:"POST",
                  body:JSON.stringify({
                    branch_id:JSON.parse(branchID),
                    date_start:from.toISOString(),
                    date_end:to.toISOString(),
                    panel_code:selectedPanel
                  })
                }).then((res) => res.json())
                  .then((result) => {
                    console.log("InvoiceListCalledByPrintPorta")

                    console.log(result)
                    var the_rows = []
                    result.map((data,index) => {
                      var br_title = ""
            
                      if (data.branch_name == "METROPOLE") {
                        br_title = "Islamabad"
                      } else {
            
                        br_title = data.branch_name.split('MPL,')[1]
            
            
                      }
                      var obj = {
                        sn:index+1,
                        ...data,
                        tests_included:data.tests_included.includes('^') ? data.tests_included.replaceAll('^','(').replaceAll('|',"),")+")" : data.tests_included.replaceAll('^','(').replaceAll('|',"),") ,
                                  bookingDateTime:new Date(data.invoice_date).ddmmyyy()+", "+msToTime(data.invoice_time)+" hrs",

                                  uploadDocument:
                                  <center>
                                    {/* this is Report  */}
                                   
                                          <Button variant="contained" color="secondary" style={{backgroundColor:"#196c91"}} disableElevation onClick={() => {
                                          // 15-july-2024  if (data.Test_Status == 'Results Ready') {
                                            
                                           
                                              // alert('Results not Ready!!')
                                              setDocumentUploadModal(true)
                                              setmr_no(data.patient_id)
                                              setinvoice_id(data.invoice_no)
                                              setpatient_name(data.patient_name.trim())

                                              setmobile_no(data.mobile_no)
                                            
                                          }} >
                                            {/* <PictureAsPdfSharpIcon /> */}
                                            <  ReceiptIcon />
                                          </Button>
                            
                                 
                            
                                  </center>
                            
                       
                     
                      }
                      the_rows.push(obj)
            
                    })

                    setRows(the_rows)
                    setOrg_Rows(the_rows)
                    setLoading(false)
                    setPage(0)
                  }).catch(()=>{
                    setLoading(false)
                    setPage(0)
                  })

              }}
            >
              Search<SearchIcon  />
            </Button>

          </GridItem>
        
        </GridContainer>
        <div align="right">

          <TextField id="standard-basic" id="search_data" label="Search by Invoice ID , MR No  , Patient Name , Mobile No ,"
            style={{ maxWidth: '35em' }}
            fullWidth={true}

            onChange={async (e) => {
              var lab_tests = []
              var text = e.target.value
              var data = await org_rows.map((item) => {
            
                if (item.mobile_no.includes(text.toLowerCase())) {
                  lab_tests.push(item)
                  return

                }

                if (item.patient_name.toLowerCase().includes(text.toLowerCase())) {
                  lab_tests.push(item)
                  return

                }

                if (item.patient_id.trim().toLowerCase().includes(text.trim().toLowerCase())) {
                  lab_tests.push(item)
                  return

                }
                if (item.invoice_no.toLowerCase().includes(text.toLowerCase())) {
                  lab_tests.push(item)
                  return

                }
                if (item.remarks.toLowerCase().includes(text.toLowerCase())) {
                  lab_tests.push(item)
                  return

                }
                if (item.tests_included.toLowerCase().includes(text.toLowerCase())) {
                  lab_tests.push(item)
                  return

                }

                return item

              })
              Promise.all(data).then((item) => {

                setRows(lab_tests)

              })

            }}
          />

        </div>
        {loading && localStorage.getItem('branch_id') != '0' ?
          <center>
            <ReactLoading type={'spinningBubbles'} color={'black'} height={'10em'} width={'10em'} />
          </center>
          :
          <>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow >
                    {columns.map((column) => (
                      // <TableCell
                      //   key={column.id}
                      //   align={column.align}
                      //   style={{ minWidth: column.minWidth }}
                      // >
                      //   {column.label}
                      // </TableCell>
                      <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth,
                        backgroundColor:"#196c91" ,
                        color:"white"
                        // backgroundColor:"#EEEEEE" 

                      }}
                    >{
                      column.label=="Patient Name"?<TableSortLabel
                      active={orderBy === 'patient_name'}
                      direction={orderBy === 'patient_name' ? order : 'asc'}
                      onClick={() => handleSort('patient_name')}
                    >
                   Patient Name
                      
                    </TableSortLabel>:column.label=="MR No"?<TableSortLabel
                      active={orderBy === 'patient_id'}
                      direction={orderBy === 'patient_id' ? order : 'asc'}
                      onClick={() => handleSort('patient_id')}
                    >
                   MR No
                      
                    </TableSortLabel>:column.label=="MPL ID"?<TableSortLabel
                      active={orderBy === 'invoice_no'}
                      direction={orderBy === 'invoice_no' ? order : 'asc'}
                      onClick={() => handleSort('invoice_no')}
                    >
                Invoice No
                      
                    </TableSortLabel>:column.label=="SN"?<TableSortLabel
                      active={orderBy === 'sn'}
                      direction={orderBy === 'sn' ? order : 'asc'}
                      onClick={() => handleSort('sn')}
                    >
                Sn
                      
                    </TableSortLabel>
              //       :column.label=="Booking D/T"?<TableSortLabel
              //         active={orderBy === 'bookingDateTime'}
              //         direction={orderBy === 'bookingDateTime' ? order : 'asc'}
              //         onClick={() => handleSort('bookingDateTime')}
              //       >
              //  Booking D/T
                      
              //       </TableSortLabel>
                    :<>
                    {column.label}</>
                    }
                   
               
                    </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row,index) => {
                    return (
                      <TableRow 
                      // 29-june-2024 hover krny se color change hota tha jab whote tha sab kch ab remove kr dya ku k row me color de dya style={{backgroundColor:index % 2 === 0 ? "" : "#EEEEEE" }}
                      // hover
                       role="checkbox" tabIndex={-1} key={row.code + Math.random()} style={{backgroundColor:index % 2 === 0 ? "" : "#EEEEEE" }} 
                      // 13-may-2024 testing for fsls lab ager reports send krdi hai to color ho jae ya status update ho jae result ready to report send
                      // style={{backgroundColor:row.restrict_to_print?"#d2f8d2":""}}
                       >
                        {columns.map((column) => {
                          // {console.log(JSON.stringify(row))}
                          // {console.log()}

                          
                          const value = row[column.id];
                          // {console.log(row[column.id])}

                          // var status_color =typeof row[column.id] === 'string'? row[column.id].includes('Just Booked') ? 'brown' : row[column.id].includes("Only Phlebotomy Done") ? 'red' :
                          // row[column.id].includes("Lying Pending Results")  ? 'purple' : row[column.id].includes("Results Done But not Signed")  ? 'blue' : row[column.id].includes("(Delivered)") ? '#008080' : row[column.id].includes("Results Ready")  ? 'green': '':""
                            // {console.log(status_color)}

                          return (
                            <>
                              {/* {status_color.trim() != '' ?
                                <TableCell key={column.id} align={column.align} style={{  color: 'white', }} >
                                  <i><b>{value}</b></i>
                                </TableCell>
                                : <TableCell key={column.id} align={column.align} >
                                  {value}
                                </TableCell>} */}
                                <TableCell key={column.id} align={column.align} >
                                  {value}
                                </TableCell>
                            </>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </>
        }


      </Paper>
      {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}

        maxWidth={'xl'}
        fullWidth={true}
        open={DocumentUploadModal}
        TransitionComponent={Transition}
        keepMounted

        onClose={() => {
          setDocumentUploadModal(false)
          // setSelected({
          //   reports: []
          // })
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
          // style={{backgroundColor:"red"}}
        >

          <h3 className={classes.modalTitle} style={{textAlign:"center"}}><b>Documents Upload</b></h3>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
           <Grid container >
            <Grid item xs={12} sm={6} md={2} lg={2}></Grid>          
      <Grid item xs={12} sm={6} md={2} lg={2}>
      <TextField required id="" label="Invoice No" value={invoice_id} />
      </Grid>
      <Grid item xs={12} sm={6} md={2} lg={2}>
        <TextField required id="" label="Patient ID" value={mr_no} />
      </Grid>
      <Grid item xs={12} sm={6} md={2} lg={2}style={{display:"flex",justifyContent:"end",alignItems:"start"}}>
        <TextField required id="" label="Patient Name" value={patient_name}/>
      </Grid>
      <Grid item xs={12} sm={6} md={2} lg={2} style={{display:"flex",justifyContent:"end",alignItems:"start"}}>
        <TextField required id="" label="Mobile No" value={mobile_no}/>
      </Grid>
      <Grid item xs={12} sm={6} md={2} lg={2}></Grid>          

     
             
      </Grid>
      {/* pdf image area */}
      <Grid container style={{marginTop:"4%",}} >
            <Grid item xs={12} sm={6} md={2} lg={2}></Grid>          
      <Grid item xs={12} sm={6} md={4} lg={4} style={{}} >
      <div>
        
          {
            !pdfScanFlag
            ?
            <Button
            variant="contained"
            color="primary"
            className={classes.button}
            style={{backgroundColor:"#196c91",paddingTop:"3%",paddingBottom:"3%"}}

          >
            <b>Upload PDF</b>
            <input type="file" variant="contained"
              color="primary"
              onChange={(e) => { handleScanPDF(e) }}
              id="ScanPDF" name="pdf" accept="application/pdf"
              className={classes.button}
              aria-label="Attach PDF"
            />
          </Button>
          :
          null
          }
{/* how to show base64 converted pdf  */}
{/* {ScanPDF&&<iframe src={ScanPDF}></iframe>} */}
         
         
         
         {
           pdfScanFlag ?
           <>
            <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            // style={{backgroundColor:"#196c91"}}
            style={{backgroundColor:"#196c91",paddingTop:"3%",paddingBottom:"3%"}}

            onClick={async()=>{
              try{
                if(document.getElementById('response').innerHTML==""){
                  
                }else{
                  setScanPDF(document.getElementById('response').innerHTML)
                }
            }
            catch(e){
              console.log(e)
              alert("Scan File First!!")
            }
            }}
          >
            <b>{ScanPDF=="" ? "Load Scan PDF File" : "Loaded SCAN PDF"}</b>
          
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            style={{backgroundColor:"#196c91",
              marginLeft:"2%",marginRight:"2%",
              paddingTop:"3%",paddingBottom:"3%"
            }}

            onClick={async()=>{
              window.scanToLocalDisk()
            }}
          >
            <b>Scan PDF</b>
            <div id="response" hidden={true} ></div>
          </Button>
          </>
          :
          null
         }
     
       
     <Button
            variant="contained"
            color="primary"
            className={classes.button}
            style={{backgroundColor:"#196c91",marginTop:"2%"}}

            >
 <FormControlLabel
              control={<Checkbox checked={pdfScanFlag} onChange={(event)=>{
                setpdfScanFlag(event.target.checked)
              }} name="Scan PDF" />}
              label="Scan PDF"
            />
            </Button>

        </div>

      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={4}  style={{display:"flex",justifyContent:"end",alignItems:"start"}}>
        {/* <TextField required id="" label="Patient ID" value={mr_no} /> */}
        {/* {
            ScanImage != "" ?
              <div style={{
                float: 'right',
                position: 'relative'
              }}>
                <img id="scanImage" src={ScanImage} alt="Scan Image" width="250px" height="300px" style={{
                  margin: "auto"
                }} />
              </div>
              : null
          } */}
   
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            // style={{backgroundColor:"#196c91"}}
            style={{backgroundColor:"#196c91",paddingTop:"3%",paddingBottom:"3%"}}

          >
            <b>Upload Image</b>
            <input type="file" variant="contained"
              color="primary"
              onChange={(e) => { handleScanImage(e) }}
              id="ScanImage" name="img" accept="image/*"
              className={classes.button}

              aria-label="Attach PDF"
            />
          </Button>
          
      </Grid>
      
      <Grid item xs={12} sm={6} md={2} lg={2}></Grid>          

     
             
      </Grid>
      <Grid container style={{marginTop:"4%"}} >
            <Grid item xs={12} sm={6} md={2} lg={2}></Grid>          
      <Grid item xs={12} sm={6} md={4} lg={4} >
     
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={4} style={{display:"flex",justifyContent:"end",alignItems:"end",}} >
      <Button
                variant="contained"
                color="primary"

                style={{
                  padding:"1em",
                  // fontSize:"1.3em",
              backgroundColor:"#196c91",
              

                }}
                className={classes.button}
                onClick={()=>{
                  if (patient_name.trim() == '') {
                    alert("Kindly Select Patient")

                    // setSaveI(false)a
                    return
                  }
                  
                  if (ScanImage=='' && ScanPDF=="" ) {
                    alert("Kindly Select Img or pdf ")

                    // setSaveI(false)a
                    return
                  }
                      
        
                       
                           var obj={
                            mr_no:mr_no,
                            user_id:localStorage.getItem('user_id'),
                              invoice_id:invoice_id,
                              dms_title_pdf:ScanPDF != "" ? patient_name + ".pdf" : "",
                              dms_title_img:ScanImage != "" ? patient_name + ".png" : "",
                              String_PDF:ScanPDF.includes("base64,") ? ScanPDF.split('base64,')[1] : ScanPDF,
                              String_IMG:ScanImage != "" ? ScanImage.split('base64,')[1] :""   
                          }
                       
                        console.log("check 445"+JSON.stringify(obj))
                 
                  fetch("https://reports.mpl-labs.pk:8443/4DACTION/DMS_ImagePDF_StoreWS_api", 
                  {
                    method:"POST",
                    body:JSON.stringify(obj)
                  }
                  ).then(res=>res.json()).then((response)=>{
             
                alert("Documents Upload successfully ! ")
                setDocumentUploadModal(false)
              //  BoxClose()
                }).catch(error => {
                     console.error('Error:', error);
                   })

                  
                }}
              >
               Upload Documents
              </Button>
          
      </Grid>
      
      <Grid item xs={12} sm={6} md={2} lg={2}></Grid>          

     
             
      </Grid>
          {/* {
            selected.name != undefined ?
              <center> <Fragment key={"sqq"}>
                <PDFViewer width="1000" height="1000" className="app" >
                  <Simple_Invoice invoice={selected} />
                </PDFViewer>
              </Fragment>
              </center>
              : <></>
          } */}
     
        </DialogContent>
        <DialogActions className={classes.modalFooter}>

          <Button
            onClick={() => {
              setDocumentUploadModal(false)
              // setSelected({
              //   reports: []
              // })
            }}
            color="danger"
            simple
          >
            Ok
                    </Button>
        </DialogActions>
      </Dialog>
    

    </>
  );
}
